import React, { useEffect, useState } from "react";
import AuctionWrapperDataTable from "./auctionWrapperDataTable";
import { RangePicker } from "react-minimal-datetime-range";
import "react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../loader/spinner";
import AuctionCard from "./auctionCard";
import { ReactTooltip } from "../util/tooltip.js";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { leagues_with_divisions_objects } from "../util/leagues";
import { getTeamMapNextGw } from "../util/teamMap";
import format from "date-fns/format";
import { withUser } from "../../userContext";
import GreenButton from "../util/greenButton";
import { errorCatcher } from "../util/errors";
import BoxWithToggle from "../util/boxWithToggle";
import { getAllPositionsBasedOnSport, getPositionsBasedOnSport } from "../util/positions";
import { allSportsOptions } from "../util/sports";
import { scarcities_objects } from "../util/scarcities";

function EndedAuctions(props) {
  const sportQuery = new URLSearchParams(props.location.search).get("sport");

  const [cardView, setCardView] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date(new Date().setHours(endDate.getHours() - 1)));
  const [auctions, setAuctions] = useState([]);
  const [displayedAuctions, setDisplayedAuctions] = useState([]);
  const [leagues, setLeagues] = useState("all");
  const [positions, setPositions] = useState("all");
  const [scarcity, setScarcity] = useState("all");
  const [order, setOrder] = useState(false);
  const [nextGwPlayers, setNextGwPlayers] = useState([]);
  const [priceInfo, setPriceInfo] = useState({});
  const [sport, setSport] = useState(sportQuery || "all");

  document.title = "Ended Sorare auctions";

  let cardViewClass = "w-11/12 mx-auto space-y-4";
  let listViewClass = "text-base w-11/12 xl:w-10/12 3xl:w-9/12 mx-auto space-y-4";

  useEffect(() => getAuctions(), []);

  const getAuctions = () => {
    let toFetch = 2000;
    let nbAuctions = 0;
    props
      .fetch("/apiv2/auctions/searchAuctions", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          limit: toFetch,
          from: nbAuctions,
          gwNumber: 0,
          u23: false,
          scarcity: scarcity,
          league: leagues,
          position: positions,
          status: "ENDED",
          order: order ? "asc" : "desc",
          start_date: startDate,
          end_date: endDate,
          sport: sport,
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        setPriceInfo(res.price_info);
        setAuctions(res.auctions);
        setDisplayedAuctions(res.auctions.slice(0, 50));
        getTeamMapNextGw(props).then((r) => setNextGwPlayers(r));
      })
      .catch(errorCatcher());
  };

  console.log(startDate, endDate);

  return (
    <div>
      <h2 className={"text-center"}>Ended auctions</h2>
      <div>
        <div className={cardView ? cardViewClass : listViewClass}>
          <div className={"flex flex-row space-x-6 justify-center"}>
            <div className={"flex flex-row p-1 bg-white rounded-lg"}>
              <BoxWithToggle setActive={() => setCardView(!cardView)} isActive={cardView} label={"Card view"} />
            </div>
            <div className={"flex flex-row p-1 bg-white rounded-lg"}>
              <BoxWithToggle setActive={() => setOrder(!order)} isActive={order} label={"Older to newer"} />
            </div>
          </div>
          <div className={"flex flex-row space-x-2 justify-center"}>
            <SelectSearch
              options={getAllPositionsBasedOnSport(sport)}
              search
              value={positions}
              onChange={(v) => setPositions(v)}
              closeOnSelect={true}
              printOptions={"on-focus"}
              filterOptions={fuzzySearch}
              placeholder="Select a position"
            />
            <SelectSearch options={allSportsOptions} value={sport} onChange={(v) => setSport(v)} />
            <SelectSearch options={scarcities_objects} onChange={(v) => setScarcity(v)} value={scarcity} />
            <SelectSearch
              options={leagues_with_divisions_objects}
              onChange={(v) => setLeagues(v)}
              search
              value={leagues}
              closeOnSelect={true}
              printOptions={"on-focus"}
              filterOptions={fuzzySearch}
              placeholder="Select one league/division or more"
            />
          </div>
          <div className={"xl:w-8/12 md:w-10/12 w-full 2xl:w-6/12 mx-auto"}>
            <RangePicker
              locale="en-us" // default is en-us
              show={false} // default is false
              disabled={false} // default is false
              allowPageClickToClose={true} // default is true
              onConfirm={(res) => {
                console.log(res);
                setStartDate(new Date(Date.parse(res[0])));
                setEndDate(new Date(Date.parse(res[1])));
              }}
              placeholder={["Start Time", "End Time"]}
              defaultDates={[format(startDate, "yyyy-MM-dd HH:mm") || startDate, format(endDate, "yyyy-MM-dd HH:mm") || endDate]}
            />
          </div>
          <div className={"text-center"} onClick={() => getAuctions()}>
            <GreenButton label={"Filter"} />
          </div>
          <p className={"text-center text-xs"}>Results return no more than 2000 auctions.</p>

          {!cardView && (
            <div className={"bg-focus rounded p-1"}>
              <AuctionWrapperDataTable tmp={"last"} nb={"200"} auctions={auctions} />
            </div>
          )}
          {cardView && (
            <div>
              <InfiniteScroll
                dataLength={displayedAuctions.length}
                onMore={() => {
                  setDisplayedAuctions(auctions.slice(0, displayedAuctions.length + 50));
                }}
                hasMore={true}
                next={() => {
                  setDisplayedAuctions(auctions.slice(0, displayedAuctions.length + 50));
                }}
                loader={displayedAuctions.length < auctions.length && <Spinner />}
              >
                <div
                  className={
                    "grid grid-cols-1 grid-rows-auto md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 " +
                    "2xl:grid-cols-6 3xl:grid-cols-7 4xl:grid-cols-8 gap-x-4 gap-y-8 mx-16"
                  }
                >
                  {displayedAuctions.map((a, i) => {
                    return (
                      <div className={"mx-auto lg:mx-0"}>
                        <AuctionCard key={a.AuctionId} auction={a} priceInfo={a.priceInfo} past={true} nextGwPlayers={nextGwPlayers} />
                      </div>
                    );
                  })}
                </div>
              </InfiniteScroll>
              <ReactTooltip />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withUser(EndedAuctions);
