export const teams = [
  { value: "12-de-octubre-itaugua", name: "Club 12 de Octubre" },
  { value: "9-de-octubre", name: "Club Nueve de Octubre" },
  { value: "a-league-all-stars", name: "Foxtel A-League All Stars" },
  { value: "aab-aalborg", name: "Aalborg BK" },
  { value: "aalesund-alesund", name: "Aalesunds FK" },
  { value: "ab-argir-streymoy", name: "AB Argir" },
  { value: "abc-natal-rio-grande-do-norte", name: "ABC FC" },
  { value: "aberdeen-aberdeen", name: "Aberdeen FC" },
  { value: "abha-abha", name: "Abha Club" },
  { value: "academico-viseu-viseu", name: "Academico de Viseu FC" },
  { value: "adana-1954-fk-adana", name: "Adana 1954 Futbol Kulübü" },
  { value: "adana-demirspor-adana", name: "Adana Demir Spor Kulübü" },
  { value: "adanaspor-adana", name: "Adanaspor AŞ" },
  { value: "adelaide-united-adelaide-2003", name: "Adelaide United FC" },
  { value: "admira-ii-maria-enzersdorf", name: "FC Admira II" },
  { value: "admira-maria-enzersdorf", name: "FC Flyeralarm Admira" },
  { value: "ado-den-haag-den-haag", name: "HFC ADO Den Haag" },
  { value: "ado-den-haag-u19-den-haag", name: "ADO Den Haag Under 19" },
  { value: "adt", name: "Asociación Deportiva Tarma" },
  { value: "aek-athens-athina-athens", name: "AEK Athens FC" },
  { value: "aek-larnaca-larnaka-larnaca", name: "AEK Larnaca" },
  { value: "ael-limassol-lemesos", name: "AEL Limassol" },
  { value: "afc-bournemouth-bournemouth-dorset", name: "AFC Bournemouth" },
  { value: "afc-wimbledon-kingston-upon-thames-surrey", name: "AFC Wimbledon" },
  { value: "agf-arhus", name: "Aarhus Gymnastikforening" },
  { value: "agri-1970-agri", name: "Ağrı 1970 Spor" },
  {
    value: "agropecuario-carlos-casares-provincia-de-buenos-aires",
    name: "Club Argentino Agropecuario de Carlos Casares",
  },
  { value: "aik-solna", name: "AIK Fotboll" },
  { value: "airdrieonians-glasgow", name: "Airdrieonians FC" },
  { value: "ajaccio-ajaccio", name: "AC Ajaccio" },
  { value: "ajax-amateurs-amsterdam", name: "AFC Ajax Amateurs" },
  { value: "ajax-amsterdam", name: "AFC Ajax" },
  { value: "ajax-ii-amsterdam", name: "AFC Ajax II" },
  { value: "akhmat-grozny-groznyi", name: "FK Akhmat Grozny" },
  { value: "akritas-chloraka", name: "Akritas Chloraka" },
  { value: "akron-togliatti", name: "FK Akron Togliatti" },
  { value: "aktobe-aqtobe-aktobe", name: "FK Aktobe" },
  { value: "al-adalh", name: "Al Adalh Club" },
  { value: "al-ahli-ad-doha-doha", name: "Al Ahli SC (Doha)" },
  { value: "al-ahli-jeddah", name: "Al Ahli SC (Jeddah)" },
  { value: "al-ahly-al-qahirah-cairo", name: "Al Ahly SC" },
  { value: "al-ain-al-atawlah-city", name: "Al-Ain FC" },
  { value: "al-ain-al-ayn-al-ain", name: "Al Ain SCC" },
  { value: "al-arabi-ad-doha-doha", name: "Al Arabi SC" },
  { value: "al-bataeh", name: "Al Bataeh Club" },
  { value: "al-batin-hafar-al-batin", name: "Al Batin Club" },
  { value: "al-duhail-ad-doha-doha", name: "Al Duhail SC" },
  { value: "al-fateh-al-hasa", name: "Al Fateh SC" },
  { value: "al-feiha", name: "Al Feiha" },
  { value: "al-gharafa-ad-doha-doha", name: "Al Gharafa SC" },
  { value: "al-hazm-ar-rass-rass", name: "Al Hazm" },
  { value: "al-hilal-ar-riyad-riyadh", name: "Al Hilal FC (Riyadh)" },
  { value: "al-hilal-omdurman-omdurman", name: "Al-Hilal FC (Omdurman)" },
  { value: "al-ittifaq-ad-dammam-dammam", name: "Al Ittifaq FC (Dammam)" },
  { value: "al-ittihad-jeddah", name: "Al Ittihad FC (Jeddah)" },
  { value: "al-ittihad-kalba-kalba", name: "Al Ittihad Kalba" },
  { value: "al-jazira-abu-zaby-abu-dhabi", name: "Al Jazira SCC" },
  { value: "al-khaleej-saihat", name: "Al Khaleej Club (Saihat)" },
  { value: "al-khor-al-khor", name: "Al Khor SC" },
  { value: "al-kuwait-madinat-al-kuwayt-kuwait-city", name: "Al Kuwait SC" },
  { value: "al-nasr-dubayy-dubai", name: "Al Nasr SC (Dubai)" },
  { value: "al-nassr-ar-riyad-riyadh", name: "Al Nassr FC" },
  { value: "al-quadisiya-al-khobar-khobar", name: "Al Quadisiya Al Khubar" },
  { value: "al-raed-buraydah-buraidah", name: "Al Raed Club" },
  { value: "al-rayyan-al-rayyan", name: "Al Rayyan SC" },
  { value: "al-sadd-ad-doha-doha", name: "Al Sadd SC" },
  { value: "al-shabab-ar-riyad-riyadh", name: "Al Shabab FC (Riyadh)" },
  { value: "al-sharjah-al-sharjah-sharjah", name: "Al Sharjah SCC" },
  { value: "al-ta-ee-ha-il-hail", name: "Al Ta'ee Ha'il" },
  { value: "al-taawon-buraydah-buraidah", name: "Al Taawon FC" },
  { value: "al-wahda-abu-zaby-abu-dhabi", name: "Al Wahda FC (Abu Dhabi)" },
  { value: "al-wahda-makkah-mecca", name: "Al Wahda FC (Mecca)" },
  { value: "al-wakrah-al-wakra", name: "Al Wakrah SC" },
  { value: "al-wasl-dubayy-dubai", name: "Al Wasl FC" },
  { value: "alajuelense-alajuela", name: "LD Alajuelense" },
  { value: "alanyaspor-alanya", name: "Alanyaspor Kulübü" },
  { value: "albacete-albacete", name: "Albacete Balompié" },
  { value: "albirex-niigata-niigata", name: "Albirex Niigata" },
  { value: "alcorcon-alcorcon", name: "AD Alcorcón" },
  { value: "alcorcon-ii-alcorcon", name: "AD Alcorcón II" },
  { value: "aldershot-town-aldershot-hampshire", name: "Aldershot Town FC" },
  {
    value: "aldosivi-mar-del-plata-provincia-de-buenos-aires",
    name: "CA Aldosivi",
  },
  { value: "algeciras-algeciras", name: "Algeciras CF" },
  { value: "alianza-atletico-sullana", name: "Club Alianza Atlético Sullana" },
  { value: "alianza-lima-lima", name: "Club Alianza Lima" },
  {
    value: "almagro-tres-de-febrero-provincia-de-buenos",
    name: "Club Almagro",
  },
  { value: "almere-city-almere", name: "Almere City FC" },
  { value: "almeria-almeria", name: "UD Almería" },
  { value: "almeria-ii-almeria", name: "UD Almería II" },
  {
    value: "almirante-brown-san-justo-provincia-de-buenos-aires",
    name: "Club Almirante Brown",
  },
  { value: "altay-izmir", name: "Altay Spor Kulübü" },
  { value: "alverca-alverca", name: "Alverca" },
  { value: "america-ciudad-de-mexico-d-f", name: "Club América" },
  {
    value: "america-mineiro-belo-horizonte-minas-gerais",
    name: "América FC (Minas Gerais)",
  },
  { value: "amiens-sc-amiens", name: "Amiens SC" },
  { value: "ancona-1905-ancona", name: "US Ancona 1905" },
  { value: "anderlecht-bruxelles-brussel", name: "RSC Anderlecht" },
  { value: "angers-sco-angers", name: "Angers Sporting Club de l'Ouest" },
  { value: "ankara-demirspor-ankara", name: "Ankara Demirspor Kulübü" },
  { value: "ankaragucu-ankara", name: "MKE Ankaragücü Spor Kulübü" },
  { value: "annecy-annecy", name: "FC Annecy" },
  { value: "anorthosis-larnaka-larnaca", name: "Anorthosis Famagusta FC" },
  { value: "ansan-greeners-ansan", name: "Ansan Greeners FC" },
  { value: "antalyaspor-antalya", name: "Antalyaspor Kulübü" },
  { value: "antofagasta-antofagasta", name: "CD Antofagasta" },
  { value: "antwerp-deurne", name: "Royal Antwerp FC" },
  { value: "anyang-anyang", name: "FC Anyang" },
  { value: "apoel-nicosia-levkosia", name: "APOEL FC" },
  { value: "apollon-limassol-lemesos", name: "Apollon Limassol" },
  { value: "apollon-smirnis-athina-athens", name: "Apollon Smirnis FC" },
  { value: "arbroath-arbroath", name: "Arbroath FC" },
  { value: "arda-kardzhali", name: "FK Arda 1924 Kardzhali" },
  {
    value: "argentinos-juniors-capital-federal-ciudad-de-buenos-aires",
    name: "Argentinos Juniors",
  },
  {
    value: "arguvan-belediyespor-malatya",
    name: "Arguvan Belediye Spor Kulübü",
  },
  { value: "aris-limassol-lemesos", name: "Aris FC Limassol" },
  { value: "aris-thessaloniki", name: "Aris Thessaloniki FC" },
  { value: "arminia-bielefeld-bielefeld", name: "DSC Arminia Bielefeld" },
  {
    value: "arnavutkoy-belediyespor-istanbul",
    name: "Arnavutköy Belediye Spor Kulübü",
  },
  { value: "arouca-arouca", name: "FC Arouca" },
  {
    value: "arsenal-avellaneda-provincia-de-buenos-aires",
    name: "Arsenal de Sarandí",
  },
  { value: "arsenal-london", name: "Arsenal FC" },
  { value: "arsenal-tula-tula", name: "FK Arsenal Tula" },
  { value: "arsenal-u18-london-colney", name: "Arsenal FC Under 18 Academy" },
  { value: "arsenal-u21-london", name: "Arsenal Under 21" },
  { value: "arys-arys", name: "FK Arys" },
  { value: "as-eupen-eupen", name: "KAS Eupen" },
  { value: "asan-mugunghwa-asan", name: "Asan Mugunghwa FC" },
  { value: "asante-kotoko-kumasi", name: "Asante Kotoko FC" },
  { value: "ascoli-ascoli", name: "Ascoli Picchio FC 1898" },
  { value: "asteras-tripolis-tripoli", name: "Asteras Tripolis FC" },
  { value: "aston-villa-birmingham", name: "Aston Villa FC" },
  { value: "atalanta-ciserano", name: "Atalanta Bergamasca Calcio" },
  { value: "atenas-san-carlos", name: "Club Atlético Atenas" },
  { value: "athletic-club-bilbao", name: "Athletic Club Bilbao" },
  {
    value: "athletico-paranaense-curitiba-parana",
    name: "Club Athletico Paranaense",
  },
  { value: "atlanta-united-atlanta-georgia", name: "Atlanta United" },
  {
    value: "atlanta-united-u19-atlanta-georgia",
    name: "Atlanta United FC U19",
  },
  { value: "atlas-delmenhorst-delmenhorst", name: "SV Atlas Delmenhorst" },
  { value: "atlas-guadalajara", name: "Atlas FC de Guadalajara" },
  { value: "atletico-cearense-fortaleza-ceara", name: "FC Atlético Cearense" },
  { value: "atletico-go-goiania-goias", name: "AC Goianiense" },
  { value: "atletico-grau-piura", name: "Club Atlético Grau" },
  { value: "atletico-madrid-madrid", name: "Club Atlético de Madrid" },
  {
    value: "atletico-mineiro-belo-horizonte-minas-gerais",
    name: "Atlético Mineiro",
  },
  { value: "atletico-ottawa", name: "Atlético Ottawa" },
  { value: "atletico-paso", name: "CD Atlético Paso" },
  {
    value: "atletico-rafaela-rafaela-provincia-de-santa-fe",
    name: "Atlético de Rafaela",
  },
  {
    value: "atletico-tucuman-san-miguel-de-tucuman-provincia-de-tucuman",
    name: "CA Tucumán",
  },
  { value: "atromitos-athina-athens", name: "PAE APS Atromitos Athens" },
  { value: "aucas-quito", name: "SD Aucas" },
  { value: "auda-kekava", name: "FK Auda Riga" },
  { value: "augsburg-augsburg", name: "FC Augsburg" },
  { value: "austin-austin-texas", name: "Austin FC" },
  {
    value: "austria-klagenfurt-klagenfurt-am-worthersee",
    name: "SK Austria Klagenfurt",
  },
  {
    value: "austria-klagenfurt-u18-klagenfurt-am-worthersee",
    name: "SK Austria Klagenfurt Under 18",
  },
  { value: "austria-lustenau-lustenau", name: "SC Austria Lustenau" },
  { value: "austria-wien-ii-wien", name: "FK Austria Wien II" },
  { value: "austria-wien-wien", name: "FK Austria Wien" },
  { value: "auxerre-auxerre", name: "Association Jeunesse Auxerroise" },
  { value: "avai-florianopolis-santa-catarina", name: "Avai FC" },
  { value: "avellino-avellino", name: "US Avellino" },
  { value: "avispa-fukuoka-fukuoka", name: "Avispa Fukuoka" },
  {
    value: "avranches-ii-avranches",
    name: "US Avranches Mont-Saint-Michel II",
  },
  { value: "ayacucho-ayacucho-huamanga", name: "Ayacucho FC" },
  { value: "az-alkmaar", name: "Alkmaar Zaanstreek" },
  { value: "azul-claro-numazu", name: "FC Azul Claro Numazu" },
  { value: "backa-topola-backa-topola", name: "FK Bačka Topola" },
  { value: "bahia-salvador-bahia", name: "EC Bahia" },
  { value: "bak-07-berlin", name: "Berliner AK '07" },
  { value: "baltika-kaliningrad", name: "FK Baltika Kaliningrad" },
  { value: "banants-yerevan", name: "FC Banants" },
  { value: "bandirmaspor-bandirma", name: "Bandırma Spor Kulübü" },
  {
    value: "banfield-lomas-de-zamora-provincia-de-buenos-aires",
    name: "CA Banfield",
  },
  { value: "bani-yas-bani-yas", name: "Bani Yas" },
  { value: "barcelona-barcelona", name: "FC Barcelona" },
  { value: "barcelona-guayaquil", name: "Barcelona SC" },
  { value: "barcelona-ii-barcelona", name: "FC Barcelona Atlètic" },
  { value: "barendrecht-barendrecht", name: "BVV Barendrecht" },
  { value: "bari-1908-bari", name: "FC Bari 1908" },
  { value: "barnsley-barnsley-south-yorkshire", name: "Barnsley FC" },
  { value: "basel-basel", name: "FC Basel 1893" },
  { value: "bastia-borgo-bastia", name: "FC Bastia-Borgo" },
  { value: "bastia-furiani", name: "SC Bastia" },
  { value: "bate-barysaw-borisov", name: "FC BATE Borisov" },
  { value: "bayer-leverkusen-leverkusen", name: "Bayer 04 Leverkusen" },
  { value: "bayern-munchen-munchen", name: "FC Bayern München" },
  { value: "bayrampasaspor-istanbul", name: "Bayrampaşa Spor Kulübü" },
  {
    value: "bb-bodrumspor-bodrum",
    name: "Bodrum Belediyesi Bodrum Spor Kulübü",
  },
  { value: "beerschot-wilrijk-wilrijk", name: "KFCO Beerschot " },
  { value: "beijing-guoan-beijing", name: "Beijing Guoan FC" },
  { value: "belenenses-lisboa", name: "Os Belenenses SAD" },
  {
    value: "belgrano-ciudad-de-cordoba-provincia-de-cordoba",
    name: "CA Belgrano de Córdoba",
  },
  { value: "bellinzona-bellinzona", name: "AC Bellinzona" },
  { value: "belouizdad-al-jaza-ir-algiers", name: "CR Belouizdad" },
  { value: "benevento-benevento", name: "Benevento Calcio" },
  { value: "benfica-lisboa", name: "SL Benfica" },
  { value: "bengaluru-bangalore", name: "Bengaluru FC" },
  { value: "bentleigh-greens-melbourne", name: "Bentleigh Greens FC" },
  { value: "bergerac-bergerac", name: "Bergerac Périgord FC" },
  { value: "beroe-stara-zagora", name: "PFC Beroe Stara Zagora" },
  { value: "besiktas-istanbul", name: "Beşiktaş Jimnastik Kulübü" },
  {
    value: "bethlehem-steel-bethlehem-pennsylvania",
    name: "Bethlehem Steel FC",
  },
  { value: "bg-pathum-united-bangkok", name: "BG Pathum United FC" },
  { value: "bhayangkara-surabaya", name: "Bhayangkara Surabaya United" },
  { value: "binh-dinh-qui-nh-n-qui-nhon", name: "SQC Binh Dinh" },
  { value: "birmingham-city-birmingham", name: "Birmingham City FC" },
  {
    value: "birmingham-legion-birmingham-alabama",
    name: "Birmingham Legion FC",
  },
  {
    value: "blackburn-rovers-blackburn-lancashire",
    name: "Blackburn Rovers FC",
  },
  {
    value: "blackburn-rovers-u21-blackburn",
    name: "Blackburn Rovers Under 21",
  },
  { value: "blackpool-blackpool-lancashire", name: "Blackpool FC" },
  { value: "blaublitz-akita-nikaho", name: "AFC Blaublitz Akita" },
  { value: "bnei-sakhnin-sakhnin", name: "Ihoud Bnei Sakhnin FC" },
  { value: "boavista-porto", name: "Boavista FC" },
  {
    value: "boca-juniors-capital-federal-ciudad-de-buenos-aires",
    name: "CA Boca Juniors",
  },
  { value: "bochum-bochum", name: "VfL Bochum 1848" },
  { value: "bohemians-dublin", name: "Bohemian FC" },
  { value: "bolivar-la-paz", name: "Club Bolívar" },
  { value: "bologna-bologna", name: "Bologna FC 1909" },
  { value: "bologna-u19", name: "Bologna Under 19" },
  { value: "bolton-wanderers-bolton", name: "Bolton Wanderers FC" },
  { value: "boluspor-bolu", name: "Boluspor Kulübü" },
  { value: "bordeaux-le-haillan", name: "FC Girondins de Bordeaux" },
  { value: "borussia-dortmund-dortmund", name: "Borussia Dortmund" },
  {
    value: "borussia-dortmund-ii-dortmund",
    name: "BV Borussia 09 Dortmund II",
  },
  {
    value: "borussia-m-gladbach-monchengladbach",
    name: "Borussia Mönchengladbach",
  },
  {
    value: "botafogo-pb-joao-pessoa-paraiba",
    name: "Botafogo FC (João Pessoa)",
  },
  { value: "botafogo-rio-de-janeiro-rio-de-janeiro", name: "Botafogo FR" },
  {
    value: "botafogo-sp-b-ribeirao-preto-sao-paulo",
    name: "Botafogo FC Ribeirão Preto B",
  },
  { value: "botev-plovdiv-plovdiv", name: "FK Botev Plovdiv" },
  { value: "botosani-botosani", name: "AFC Botoşani" },
  {
    value: "boulogne-boulogne-sur-mer",
    name: "US Boulogne-sur-Mer Côte d'Opale",
  },
  {
    value: "bourg-en-bresse-peronnas",
    name: "Football Bourg-en-Bresse Péronnas 01",
  },
  {
    value: "bragantino-braganca-paulista-sao-paulo",
    name: "Clube Atlético Bragantino",
  },
  { value: "brann-bergen", name: "SK Brann" },
  { value: "bravo-ljubljana", name: "AŠK Bravo Publikum" },
  { value: "brentford-brentford-middlesex", name: "Brentford FC" },
  { value: "brescia-brescia", name: "Brescia Calcio" },
  { value: "brest-brest", name: "Stade Brestois 29" },
  {
    value: "brighton-hove-albion-brighton-east-sussex",
    name: "Brighton & Hove Albion FC",
  },
  { value: "brisbane-roar-brisbane", name: "Brisbane Roar FC" },
  { value: "bristol-city-bristol", name: "Bristol City FC" },
  {
    value: "bristol-rovers-bristol-gloucestershire",
    name: "Bristol Rovers FC",
  },
  { value: "brondby-brondby", name: "Brøndby IF" },
  { value: "bucheon-1995-bucheon", name: "Bucheon FC 1995" },
  { value: "buducnost-podgorica", name: "FK Budućnost Podgorica" },
  { value: "bunyodkor-toshkent-tashkent", name: "FK Bunyodkor" },
  { value: "burgos-burgos", name: "Burgos CF" },
  { value: "buriram-united-bangkok", name: "Buriram United FC" },
  { value: "burnley-burnley-lancashire", name: "Burnley FC" },
  { value: "bursaspor-bursa", name: "Bursaspor Kulübü Derneği" },
  {
    value: "burton-albion-burton-upon-trent-staffordshire",
    name: "Burton Albion FC",
  },
  { value: "busan-i-park-busan", name: "Busan I'Park" },
  {
    value: "busan-transportation-busan",
    name: "Busan Transportation Corporation FC",
  },
  { value: "c-chartres-chartres", name: "C' Chartres Football" },
  { value: "cadiz-cadiz", name: "Cádiz CF" },
  { value: "cadiz-ii-cadiz", name: "Cádiz CF II" },
  { value: "caen-caen", name: "Stade Malherbe Caen" },
  { value: "cagliari-cagliari", name: "Cagliari Calcio" },
  { value: "calcio-padova-padova", name: "Calcio Padova Spa" },
  {
    value: "cambridge-united-cambridge-cambridgeshire",
    name: "Cambridge United FC",
  },
  { value: "cambuur-leeuwarden", name: "SC Cambuur Leeuwarden" },
  { value: "cape-town-city-cape-town", name: "Cape Town City FC" },
  { value: "cardiff-city-cardiff", name: "Cardiff City FC" },
  { value: "cardiff-city-u21-cardiff", name: "Cardiff City Under 21" },
  { value: "carlos-manucci-trujillo", name: "CSD Carlos A. Manucci" },
  { value: "casa-pia-lisboa", name: "Casa Pia AC" },
  { value: "castellon-castellon-de-la-plana", name: "CD Castellón" },
  { value: "cavalry", name: "Cavalry FC" },
  { value: "ceara-fortaleza-ceara", name: "Ceará SC" },
  { value: "celaya-celaya", name: "Celaya CF" },
  { value: "celje-celje", name: "NK Celje" },
  { value: "celta-de-vigo-ii-vigo", name: "Real Club Celta de Vigo II" },
  { value: "celta-de-vigo-vigo", name: "Real Club Celta de Vigo" },
  { value: "celtic-glasgow", name: "Celtic FC" },
  { value: "celtic-ii-glasgow", name: "Celtic FC II" },
  {
    value: "central-coast-mariners-tuggerah",
    name: "Central Coast Mariners FC",
  },
  {
    value: "central-cordoba-sde-santiago-del-estero-provincia-de-santiago-del-estero",
    name: "CA Central Córdoba de Santiago del Estero",
  },
  {
    value: "ceov-operario-varzea-grande-mato-grosso",
    name: "Clube Esportivo Operário Várzea-Grandense",
  },
  { value: "cercle-brugge-brugge", name: "Cercle Brugge KSV" },
  { value: "cerezo-osaka-osaka-osaka", name: "Cerezo Osaka" },
  { value: "cerezo-osaka-u23-osaka", name: "Cerezo Osaka Under 23" },
  { value: "cesena-cesena", name: "ASD Romagna Centro Cesena" },
  { value: "cfr-cluj-cluj-napoca", name: "SCS CFR 1907 Cluj" },
  {
    value: "chacarita-juniors-capital-federal-ciudad-de-buenos-aires",
    name: "CA Chacarita Juniors",
  },
  { value: "chainat-hornbill-chainat", name: "Chainat Hornbill FC" },
  { value: "chamalieres", name: "Chamalières FC" },
  { value: "changchun-yatai-changchun", name: "Changchun Yatai FC" },
  { value: "changwon-city-changwon", name: "Changwon City Government FC" },
  { value: "chapecoense-chapeco-santa-catarina", name: "Chapecoense AF" },
  {
    value: "charleston-battery-charleston-south-carolina",
    name: "Charleston Battery",
  },
  { value: "charlotte-fc-charlotte-north-carolina", name: "Charlotte FC" },
  {
    value: "charlotte-independence-charlotte-north-carolina",
    name: "Charlotte Independence",
  },
  {
    value: "charlton-athletic-london-greater-london",
    name: "Charlton Athletic FC",
  },
  { value: "chateauroux-chateauroux", name: "La Berrichonne de Châteauroux" },
  {
    value: "chateauroux-ii-chateauroux",
    name: "La Berrichonne Châteauroux II",
  },
  { value: "chaves-chaves", name: "GD Chaves" },
  { value: "chayka-peschanokopskoe", name: "FK Chayka Peschanokopskoye" },
  { value: "chelsea-london", name: "Chelsea FC" },
  { value: "chelsea-u21-london", name: "Chelsea FC Under 21" },
  { value: "chengdu-qianbao-chengdu", name: "Chengdu Rongcheng F.C" },
  { value: "cheonan-city-cheonan", name: "Cheonan City Government FC" },
  { value: "cheongju-cheongju", name: "Cheongju FC" },
  { value: "chiangmai-chiangmai", name: "Chiangmai FC" },
  { value: "chiangrai-united-chiang-rai", name: "Singha Chiangrai United FC" },
  { value: "chicago-fire-bridgeview-illinois", name: "Chicago Fire FC" },
  { value: "chicago-fire-ii-bridgeview-illinois", name: "Chicago" },
  { value: "chicago-fire-u17", name: "Chicago" },
  { value: "cholet-cholet", name: "SO Choletais" },
  { value: "cienciano-cusco", name: "Club Cienciano" },
  { value: "cimarrones-de-sonora-hermosillo", name: "Cimarrones de Sonora FC" },
  { value: "cincinnati-cincinnati-ohio", name: "FC Cincinnati" },
  {
    value: "circulo-deportivo-comandante-nicanor-otamendi-provincia-de-buenos-aires",
    name: "Club Circulo Deportivo",
  },
  { value: "cittadella-cittadella", name: "AS Cittadella" },
  { value: "clermont-clermont-ferrand", name: "Clermont Foot 63" },
  { value: "club-africain-tunis", name: "Club Africain" },
  {
    value: "club-atletico-guemes-santiago-del-estero-provincia-de-santiago-del-estero",
    name: "Club Atlético Güemes de Santiago del Estero",
  },
  { value: "club-brugge-brugge", name: "Club Brugge KV" },
  { value: "club-brugge-ii-brugge", name: "Club Brugge KV II" },
  { value: "colo-colo-santiago-de-chile", name: "CSD Colo-Colo" },
  {
    value: "colon-ciudad-de-santa-fe-provincia-de-santa-fe",
    name: "Colón de Santa Fe",
  },
  { value: "colorado-rapids-denver-colorado", name: "Colorado Rapids" },
  {
    value: "colorado-springs-colorado-springs",
    name: "Colorado Springs Switchbacks FC",
  },
  { value: "columbus-crew-columbus-ohio", name: "Columbus Crew" },
  { value: "columbus-crew-ii-columbus-ohio", name: "Columbus Crew II" },
  { value: "como-como", name: "Calcio Como" },
  { value: "consadole-sapporo-sapporo", name: "Consadole Sapporo" },
  { value: "coquimbo-unido-coquimbo", name: "CD Coquimbo Unido" },
  { value: "cordoba-cordoba", name: "Córdoba CF" },
  { value: "cordoba-ii-cordoba", name: "Córdoba CF II" },
  { value: "corinthians-sao-paulo", name: "SC Corinthians Paulista" },
  { value: "correcaminos-uat-ciudad-victoria", name: "Correcaminos de la UAT" },
  { value: "cortulua-tulua", name: "Corporación Club Deportivo Tuluá" },
  { value: "cosenza-cosenza", name: "Cosenza Calcio" },
  { value: "cotonsport-garoua", name: "Cotonsport de Garoua" },
  { value: "cove-rangers-aberdeen", name: "Cove Rangers FC" },
  { value: "coventry-city-coventry", name: "Coventry City FC" },
  { value: "cracovia-krakow-krakow", name: "MKS Cracovia Kraków" },
  { value: "crawley-town-crawley-west-sussex", name: "Crawley Town FC" },
  { value: "crb-maceio-alagoas", name: "Clube de Regatas Brasil" },
  { value: "cremonese-cremona", name: "US Cremonese" },
  { value: "creteil-ii-creteil", name: "US Créteil Lusitanos II" },
  { value: "crewe-alexandra-crewe-cheshire", name: "Crewe Alexandra FC" },
  { value: "criacao-shinjuku-tokyo", name: "Criacao Shinjuku" },
  { value: "croix-football-ic-croix", name: "Croix Football Iris Club" },
  { value: "cruz-azul-ciudad-de-mexico-d-f", name: "Cruz Azul FC" },
  { value: "cruzeiro-belo-horizonte-minas-gerais", name: "Cruzeiro EC" },
  { value: "crvena-zvezda-beograd", name: "FK Crvena Zvezda Beograd" },
  { value: "crystal-palace-london", name: "Crystal Palace FC" },
  { value: "crystal-palace-u21-london", name: "Crystal Palace Under 21" },
  { value: "cs-sfaxien-sfax-safaqis", name: "CS Sfaxien" },
  { value: "csa-maceio-alagoas", name: "CS Alagoano" },
  { value: "cska-moskva-moskva", name: "PFC CSKA" },
  { value: "cska-sofia-sofia", name: "PFC CSKA Sofia" },
  {
    value: "cucuta-deportivo-cucuta",
    name: "Corporación Nuevo Cúcuta Deportivo",
  },
  { value: "cuiaba-cuiaba-mato-grosso", name: "Cuiabá EC" },
  { value: "cukaricki-beograd", name: "FK Čukarički Stankom" },
  { value: "daegu-daegu", name: "Daegu FC" },
  { value: "daejeon-citizen-daejeon", name: "Daejeon Citizen FC" },
  { value: "daejeon-korail-daejeon", name: "Daejeon Korail FC" },
  { value: "dallas-frisco-texas", name: "FC Dallas" },
  { value: "dangjin-citizen-dangjin", name: "Dangjin Citizen FC" },
  { value: "danubio-montevideo", name: "Danubio Fútbol Club" },
  { value: "darmstadt-98-darmstadt", name: "SV Darmstadt 1898" },
  { value: "dc-united-washington-district-of-columbia", name: "DC United" },
  { value: "de-graafschap-doetinchem", name: "BV De Graafschap" },
  { value: "debrecen-debrecen", name: "Debreceni VSC" },
  {
    value: "defensa-y-justicia-florencio-varela-provincia-de-buenos-aires",
    name: "CSD Defensa y Justicia",
  },
  { value: "defensor-sporting-montevideo", name: "Defensor Sporting Club" },
  { value: "degerfors-degerfors", name: "Degerfors IF" },
  { value: "deinze-deinze", name: "KMSK Deinze" },
  { value: "delfin-manta", name: "Delfín SC" },
  { value: "den-bosch-s-hertogenbosch", name: "FC Den Bosch" },
  {
    value: "dender-denderleeuw",
    name: "FC Verbroedering Dender Eendracht Hekelgem",
  },
  { value: "deportivo-alaves-vitoria-gasteiz", name: "Deportivo Alavés" },
  {
    value: "deportivo-binacional-desaguadero",
    name: "Escuela Municipal Deportivo Binacional",
  },
  {
    value: "deportivo-cali-santiago-de-cali",
    name: "Asociación Deportivo Cali",
  },
  { value: "deportivo-coopsol-chancay", name: "Club Deportivo Coopsol" },
  { value: "deportivo-cuenca-cuenca", name: "CD Cuenca" },
  {
    value: "deportivo-la-coruna-a-coruna",
    name: "Real Club Deportivo de La Coruña",
  },
  { value: "deportivo-la-guaira-caracas", name: "Deportivo La Guaira" },
  {
    value: "deportivo-moron-moron-provincia-de-buenos-aires",
    name: "CD Morón",
  },
  {
    value: "deportivo-municipal-lima",
    name: "Club Centro Deportivo Municipal",
  },
  { value: "deportivo-pasto-san-juan-de-pasto", name: "AD Pasto" },
  {
    value: "deportivo-saprissa-san-juan-de-tibas-san-jose",
    name: "Deportivo Saprissa",
  },
  { value: "deportivo-tachira-san-cristobal", name: "Deportivo Táchira FC" },
  { value: "detroit-city-detroit-michigan", name: "Detroit City FC" },
  { value: "dhamk", name: "Dhamk Club" },
  { value: "dijon-dijon", name: "Dijon FCO" },
  { value: "dinamo-moskva-moskva", name: "FC Dynamo Moscow" },
  { value: "dinamo-tbilisi-tbilisi", name: "FC Dinamo Tbilisi" },
  { value: "dinamo-zagreb-zagreb", name: "GNK Dinamo Zagreb" },
  { value: "diosgyor-miskolc", name: "Diósgyőri VTK" },
  { value: "diyarbekirspor-diyarbakir", name: "Diyarbekirspor AŞ" },
  { value: "djurgarden-stockholm", name: "Djurgårdens IF" },
  { value: "dnipro-1-dnipropetrovs-k", name: "SK Dnipro-1" },
  { value: "domzale-domzale", name: "NK Domžale" },
  { value: "dorados-culiacan", name: "CSyD Dorados de Sinaloa" },
  { value: "dordrecht-dordrecht", name: "FC Dordrecht" },
  { value: "dornbirn-dornbirn", name: "FC Dornbirn 1913" },
  { value: "drenica-skenderaj-skenderaj-srbica", name: "KF Drenica Skënderaj" },
  { value: "dubrava-zagreb-zagreb", name: "NK Dubrava Zagreb" },
  { value: "dundee-dundee", name: "Dundee FC" },
  { value: "dundee-united-dundee", name: "Dundee United FC" },
  { value: "dunkerque-dunkerque", name: "US du Littoral de Dunkerque" },
  { value: "durango-durango", name: "CF Alacranes de Durango" },
  { value: "duzcespor-duzce", name: "Düzce Spor Kulübü" },
  { value: "dvs-33-ermelo", name: "Door Vereniging Sterk '33 Ermelo" },
  { value: "dynamo-dresden-dresden", name: "SG Dynamo Dresden" },
  { value: "dynamo-kyiv-kyiv-kiev", name: "FC Dynamo Kyiv" },
  { value: "edinburgh-city-edinburgh", name: "Edinburgh City FC" },
  { value: "edmonton-edmonton-alberta", name: "FC Edmonton" },
  { value: "ehime-matsuyama", name: "Ehime FC" },
  { value: "eibar-eibar", name: "SD Eibar" },
  { value: "eindhoven-eindhoven", name: "FC Eindhoven" },
  {
    value: "eintracht-braunschweig-braunschweig",
    name: "Braunschweiger TSV Eintracht 1895",
  },
  {
    value: "eintracht-frankfurt-frankfurt-am-main",
    name: "Eintracht Frankfurt",
  },
  { value: "el-gounah-el-gouna", name: "El Gounah" },
  { value: "el-paso-locomotive-el-paso-texas", name: "El Paso Locomotive FC" },
  { value: "elche-elche", name: "Elche CF" },
  { value: "elfsborg-boras", name: "IF Elfsborg" },
  { value: "emelec-guayaquil", name: "CS Emelec" },
  { value: "emmen-emmen", name: "FC Emmen" },
  { value: "empoli-empoli", name: "Empoli FC" },
  { value: "energie-cottbus-cottbus", name: "FC Energie Cottbus" },
  { value: "erzgebirge-aue-aue", name: "FC Erzgebirge Aue" },
  { value: "erzin-belediyespor-hatay", name: "Erzin Belediye Spor Kulübü" },
  { value: "es-tunis-tunis", name: "Espérance Sportive de Tunis" },
  { value: "esbjerg-esbjerg", name: "Esbjerg fB" },
  { value: "espanyol-barcelona", name: "Reial Club Deportiu Espanyol" },
  { value: "esteghlal-tehran-teheran", name: "Esteghlal FC" },
  { value: "estoril-estoril", name: "GD Estoril Praia" },
  {
    value: "estudiantes-la-plata-provincia-de-buenos-aires",
    name: "Club Estudiantes de La Plata",
  },
  { value: "estudiantes-merida-merida", name: "Estudiantes de Mérida FC" },
  {
    value: "estudiantes-rio-cuarto-rio-cuarto-provincia-de-cordoba",
    name: "AA Estudiantes de Río Cuarto",
  },
  {
    value: "etimesgut-belediyespor-etimesgut",
    name: "Etimesgut Belediye Spor Kulübü",
  },
  { value: "etoile-du-sahel-sousse", name: "Étoile Sportive du Sahel" },
  { value: "everton-liverpool", name: "Everton FC" },
  { value: "excelsior-rotterdam", name: "SBV Excelsior" },
  { value: "excelsior-virton-virton", name: "Royal Excelsior Virton" },
  { value: "eyupspor-istanbul", name: "Eyüp Spor Kulübü" },
  { value: "fagiano-okayama-okayama", name: "Fagiano Okayama" },
  { value: "fakel-voronezh", name: "FK Fakel Voronezh" },
  { value: "famalicao-vila-nova-de-famalicao", name: "FC Famalicão" },
  { value: "farense-faro", name: "SC Farense" },
  { value: "fatih-karagumruk-istanbul", name: "Fatih Karagümrük Spor Kulübü" },
  {
    value: "fatih-karagumruk-res-istanbul",
    name: "Fatih Karagümrük Spor Kulübü Reserves",
  },
  {
    value: "fatih-karagumrukspor-u19-istanbul",
    name: "Fatih Karagümrükspor AŞ Under 19",
  },
  { value: "fc-andorra", name: "FC Andorra" },
  { value: "fc-cartagena-cartagena", name: "FC Cartagena" },
  { value: "fc-schaffhausen-schaffhausen", name: "FC Schaffhausen" },
  { value: "fenerbahce-istanbul", name: "Fenerbahçe Spor Kulübü" },
  { value: "fenerbahce-u19-istanbul", name: "Fenerbahçe Spor Kulübü Under 19" },
  { value: "ferencvaros-budapest", name: "Ferencvárosi TC" },
  {
    value: "ferro-carril-oeste-capital-federal-ciudad-de-buenos-aires",
    name: "Club Ferro Carril Oeste",
  },
  { value: "feyenoord-rotterdam", name: "Feyenoord Rotterdam" },
  { value: "feyenoord-u21-rotterdam", name: "Feyenoord Rotterdam Under 21" },
  { value: "fiorentina-firenze", name: "ACF Fiorentina" },
  { value: "first-vienna-wien", name: "First Vienna FC 1894" },
  { value: "fk-aksu", name: "FK Aksu" },
  { value: "flamengo-rio-de-janeiro-rio-de-janeiro", name: "CR Flamengo" },
  { value: "fleetwood-town-fleetwood-lancashire", name: "Fleetwood Town FC" },
  { value: "floridsdorfer-ac-wien", name: "Floridsdorfer AC" },
  { value: "fluminense-rio-de-janeiro-rio-de-janeiro", name: "Fluminense FC" },
  {
    value: "forest-green-rovers-nailsworth-gloucestershire",
    name: "Forest Green Rovers FC",
  },
  { value: "forfar-athletic-forfar", name: "Forfar Athletic FC" },
  { value: "forge", name: "Forge FC" },
  { value: "forres-mechanics-elgin", name: "Forres Mechanics FC" },
  { value: "fortaleza-fortaleza-ceara", name: "Fortaleza EC" },
  {
    value: "fortuna-dusseldorf-dusseldorf",
    name: "Düsseldorfer TuS Fortuna 1895",
  },
  { value: "fortuna-sittard-sittard", name: "Fortuna Sittard" },
  { value: "forward-madison-madison-wisconsin", name: "Forward Madison FC" },
  { value: "francs-borains-boussu", name: "Royal Francs Borains" },
  { value: "freiburg-freiburg-im-breisgau", name: "Sport-Club Freiburg" },
  { value: "frosinone-frosinone", name: "Frosinone Calcio" },
  { value: "fujieda-myfc-fujieda", name: "Fujieda MYFC" },
  { value: "fukushima-united-fukushima", name: "Fukushima United FC" },
  { value: "fulham-london", name: "Fulham FC" },
  { value: "fulham-u23-london", name: "Fulham Under 23" },
  { value: "gainare-tottori-tottori", name: "Gainare Tottori" },
  { value: "galatasaray-istanbul", name: "Galatasaray Spor Kulübü" },
  { value: "gamba-osaka-suita-shi-suita", name: "Gamba Osaka" },
  { value: "gangneung-city-gangneung", name: "Gangneung City Government FC" },
  { value: "gangwon-gangneung", name: "Gangwon FC" },
  {
    value: "gazisehir-gaziantep-gaziantep",
    name: "Gazişehir Gaziantep Futbol Kulübü",
  },
  { value: "genclerbirligi-ankara", name: "Gençlerbirliği Spor Kulübü" },
  { value: "general-caballero-jlm", name: "General Caballero JLM" },
  { value: "genk-genk", name: "KRC Genk" },
  { value: "genoa-genova", name: "Genoa CFC" },
  { value: "gent-gent", name: "KAA Gent" },
  { value: "getafe-getafe-madrid", name: "Getafe Club de Fútbol" },
  { value: "gif-sundsvall-sundsvall", name: "GIF Sundsvall" },
  { value: "gifu-gifu", name: "FC Gifu" },
  { value: "gil-vicente-barcelos", name: "Gil Vicente FC" },
  { value: "gillingham-gillingham-kent", name: "Gillingham FC" },
  { value: "gimhae-city-gimhae", name: "Gimhae City Government FC" },
  {
    value: "gimnasia-la-plata-la-plata-provincia-de-buenos-aires",
    name: "Gimnasia y Esgrima La Plata",
  },
  { value: "gimpo-citizen-gimpo", name: "Gimpo Citizen FC" },
  { value: "giravanz-kitakyushu-kitakyushu", name: "Giravanz Kitakyushu" },
  { value: "giresunspor-giresun", name: "Giresun Spor Kulübü" },
  { value: "girona-girona", name: "Girona FC" },
  { value: "go-ahead-eagles-deventer", name: "Go Ahead Eagles" },
  { value: "gobelins-paris", name: "FC Gobelins" },
  {
    value: "godoy-cruz-godoy-cruz-provincia-de-mendoza",
    name: "Godoy Cruz Antonio Tomba",
  },
  { value: "goias-goiania-goias", name: "Goiás EC" },
  { value: "gol-gohar-sirjan", name: "Gol Gohar FC Sirjan" },
  { value: "gorica-velika-gorica", name: "HNK Gorica" },
  { value: "gornik-zabrze-zabrze", name: "KS Górnik Zabrze" },
  { value: "goztepe-izmir", name: "Göztepe Spor Kulübü" },
  { value: "granada-granada", name: "Granada CF" },
  { value: "grasshopper-niederhasli", name: "Grasshopper Club Zürich" },
  { value: "grazer-ak-graz", name: "Liebherr Grazer AK" },
  { value: "grecia-grecia", name: "AD Grecia" },
  { value: "greifswalder-fc-greifswald-hansestadt", name: "Greifswalder FC" },
  {
    value: "gremio-porto-alegre-rio-grande-do-sul",
    name: "Grêmio FB Porto Alegrense",
  },
  { value: "grenoble-foot-38-grenoble", name: "Grenoble Foot 38" },
  { value: "greuther-furth-furth", name: "SpVgg Greuther Fürth" },
  { value: "greuther-furth-ii-furth", name: "SpVgg Greuther Fürth II" },
  { value: "groene-ster-heerlerheide", name: "RKSV Groene Ster Heerlerheide" },
  { value: "groningen-groningen", name: "FC Groningen" },
  { value: "grulla-morioka-morioka", name: "Grulla Morioka" },
  { value: "guadalajara-zapopan-guadalajara", name: "CD Guadalajara" },
  { value: "guairena-villarrica", name: "Guaireña FC" },
  { value: "guanacasteca-nicoya", name: "AD Guanacasteca" },
  {
    value: "guangxi-pingguo-liuzhou-guangxi",
    name: "Guangxi Pingguo Haliao FC",
  },
  { value: "guarani-campinas-sao-paulo", name: "Guarani FC de Campinas" },
  { value: "guayaquil-city-guayaquil", name: "Guayaquil City FC" },
  {
    value: "guillermo-brown-puerto-madryn-provincia-de-chubut",
    name: "Club Social y Atlético Guillermo Brown de Puerto Madryn",
  },
  { value: "guingamp-guingamp", name: "En Avant Guingamp" },
  { value: "gumushanespor-gumushane", name: "Gümüşhane Spor Kulübü" },
  { value: "gurten-gurten", name: "Union Gurten" },
  { value: "gvvv-veenendaal", name: "Gelders Veenendaalse Voetbal Vereniging" },
  { value: "gwangju-gwangju", name: "Gwangju FC" },
  {
    value: "gyeongju-hnp-seoul",
    name: "Gyeongju Korea Hydro & Nuclear Power FC",
  },
  { value: "gyeongnam-changwon", name: "Gyeongnam FC" },
  { value: "ha-noi-ha-n-i-hanoi", name: "Ha Noi FC" },
  { value: "hacken-goteborg", name: "BK Häcken" },
  { value: "hajduk-split-split", name: "HNK Hajduk Split" },
  { value: "hallescher-fc-halle-salle", name: "Hallescher FC" },
  { value: "hamburger-sv-hamburg", name: "Hamburger SV" },
  { value: "hamkam-hamar", name: "Hamarkameratene Fotball" },
  { value: "hammarby-stockholm", name: "Hammarby IF" },
  { value: "hannover-96-hannover", name: "Hannoverscher Sportverein 1896" },
  { value: "hansa-rostock-rostock", name: "FC Hansa Rostock" },
  {
    value: "hapoel-be-er-sheva-be-er-sheva-beer-sheva",
    name: "Hapoel Be'er Sheva FC",
  },
  { value: "hapoel-hadera", name: "Hapoel Hadera Shulam Shwartz FC" },
  { value: "hapoel-katamon-jerusalem", name: "Hapoel Katamon Jerusalem FC" },
  {
    value: "hapoel-ramat-hasharon-ramat-ha-sharon",
    name: "Hapoel Nir Ramat HaSharon FC",
  },
  {
    value: "hapoel-tel-aviv-tel-aviv-yafa-tel-aviv",
    name: "Hapoel Tel Aviv FC",
  },
  { value: "hartberg-hartberg", name: "TSV Egger Glas Hartberg" },
  {
    value: "hartford-athletic-hartford-connecticut",
    name: "Hartford Athletic",
  },
  { value: "hatayspor-hatay", name: "Hatay Spor Kulübü" },
  { value: "haugesund-haugesund", name: "FK Haugesund" },
  { value: "hearts-edinburgh", name: "Heart of Midlothian FC" },
  { value: "hearts-of-oak-accra", name: "Hearts of Oak SC" },
  { value: "heerenveen-heerenveen-1920", name: "SC Heerenveen" },
  {
    value: "heidenheim-heidenheim-an-der-brenz",
    name: "1. FC Heidenheim 1846",
  },
  { value: "heist-heist-op-den-berg", name: "KSK Heist" },
  { value: "hekimoglu-trabzon-trabzon", name: "Hekimoğlu Trabzon" },
  { value: "hellas-verona-verona", name: "Hellas Verona FC" },
  { value: "helmond-sport-helmond", name: "Helmond Sport" },
  { value: "henan-jianye-zhengzhou", name: "Henan Jianye FC" },
  { value: "heracles-almelo", name: "Heracles Almelo" },
  { value: "herediano-heredia", name: "CS Herediano" },
  { value: "hertha-bsc-berlin", name: "Hertha Berlin" },
  { value: "hertha-bsc-u21-berlin", name: "Hertha BSC U21" },
  { value: "hhc-hardenberg", name: "Hardenberg Heemse Combinatie" },
  { value: "hibernian-edinburgh", name: "Hibernian FC" },
  { value: "hjk-helsinki", name: "Helsingin Jalkapalloklubi" },
  {
    value: "ho-chi-minh-city-thanh-ph-h-chi-minh-ho-chi-minh-city",
    name: "Ho Chi Minh City FC",
  },
  { value: "hoang-anh-gia-lai-plei-cu-pleiku", name: "Hoang Anh Gia Lai" },
  { value: "hobro-hobro", name: "Hobro IK" },
  { value: "hoffenheim-ii-hoffenheim", name: "TSG 1899 Hoffenheim II" },
  { value: "hoffenheim-sinsheim", name: "TSG Hoffenheim" },
  { value: "hohenems-hohenems", name: "VfB Hohenems" },
  { value: "holstein-kiel-kiel", name: "Kieler SV Holstein 1900" },
  { value: "home-united-singapore", name: "Home United FC" },
  { value: "honved-budapest", name: "Budapest Honvéd FC" },
  { value: "horn-horn", name: "SV Horn" },
  { value: "horsens-horsens", name: "AC Horsens" },
  { value: "houston-dynamo-houston-texas", name: "Houston Dynamo FC" },
  { value: "hrvatski-dragovoljac-zagreb", name: "NK Hrvatski Dragovoljac" },
  { value: "hsv-hoek-hoek", name: "Hoekse Sportvereniging Hoek" },
  { value: "huachipato-talcahuano", name: "CD Huachipato" },
  {
    value: "huddersfield-town-huddersfield-west-yorkshire",
    name: "Huddersfield Town FC",
  },
  { value: "huesca-huesca", name: "SD Huesca" },
  { value: "hull-city-hull", name: "Hull City AFC" },
  {
    value: "huracan-capital-federal-ciudad-de-buenos-aires",
    name: "CA Huracán",
  },
  { value: "hwaseong-hwaseong", name: "Hwaseong FC" },
  { value: "ifk-goteborg-goteborg", name: "IFK Göteborg" },
  { value: "ijsselmeervogels-bunschoten", name: "IJsselmeervogels Spakenburg" },
  { value: "imabari", name: "FC Imabari" },
  { value: "imbabura-ibarra", name: "Imbabura SC" },
  { value: "incheon-united-incheon", name: "Incheon United FC" },
  {
    value: "independiente-avellaneda-provincia-de-buenos-aires",
    name: "CA Independiente",
  },
  {
    value: "independiente-del-valle-sangolqui",
    name: "CSD Independiente del Valle",
  },
  {
    value: "independiente-medellin-medellin",
    name: "Deportivo Independiente Medellín",
  },
  {
    value: "independiente-petrolero-chuquisaca",
    name: "Club Independiente Petrolero",
  },
  { value: "indy-eleven-indianapolis-indiana", name: "Indy Eleven" },
  { value: "ingolstadt-ingolstadt", name: "FC Ingolstadt 04" },
  { value: "inter-miami", name: "Inter Miami CF" },
  { value: "inter-turku-turku-abo", name: "FC Inter Turku" },
  {
    value: "internacional-porto-alegre-rio-grande-do-sul",
    name: "SC Internacional",
  },
  { value: "internazionale-milano", name: "FC Internazionale Milano" },
  { value: "ionikos-nikea", name: "PAE Ionikos Nikaias" },
  { value: "iraklis-1908-thessaloniki", name: "Iraklis 1908 FC" },
  { value: "isparta-davrazspor-isparta", name: "Isparta Davraz Spor Kulübü" },
  {
    value: "istanbul-basaksehir-istanbul",
    name: "İstanbul Başakşehir Futbol Kulübü",
  },
  { value: "istanbulspor-istanbul", name: "İstanbulspor AŞ" },
  { value: "istanbulspor-u19-istanbul", name: "İstanbulspor AŞ Under 19" },
  { value: "istra-1961-pula", name: "NK Istra 1961" },
  { value: "ituano-itu-sao-paulo", name: "Ituano Futebol Clube" },
  { value: "iwaki", name: "Iwaki SC" },
  { value: "jablonec-jablonec-nad-nisou", name: "FK Jablonec" },
  {
    value: "jagiellonia-bialystok-bialystok",
    name: "SSA Jagiellonia Białystok",
  },
  { value: "jahn-regensburg-regensburg", name: "SSV Jahn 2000 Regensburg" },
  { value: "jef-united-chiba", name: "JEF United Ichihara Chiba" },
  { value: "jeju-united-seogwipo-jeju-do", name: "Jeju United FC" },
  { value: "jeonbuk-motors-jeonju", name: "Jeonbuk Hyundai Motors FC" },
  { value: "jeonnam-dragons-gwangyang", name: "Jeonnam Dragons FC" },
  { value: "jerv-grimstad", name: "FK Jerv" },
  { value: "johor-darul-ta-zim-pasir-gudang", name: "Johor Darul Ta'zim FC" },
  { value: "juarez-ciudad-juarez-chihuahua", name: "FC Juárez" },
  { value: "jubilo-iwata-iwata", name: "Júbilo Iwata" },
  { value: "junior-barranquilla", name: "CD Popular Junior FC SA" },
  { value: "juventude-caxias-do-sul-rio-grande-do-sul", name: "EC Juventude" },
  { value: "juventus-ii-torino", name: "Juventus Under 23" },
  { value: "juventus-torino", name: "Juventus FC" },
  { value: "kaa-gent-ii-gent", name: "KAA Gent B" },
  { value: "kaan-marienborn-siegen", name: "1. FC Kaan-Marienborn 07" },
  { value: "kairat-almati-almaty", name: "FK Kairat Almaty" },
  { value: "kaiserslautern-kaiserslautern", name: "1. FC Kaiserslautern" },
  { value: "kamatamare-sanuki-takamatsu", name: "Kamatamare Sanuki" },
  { value: "kamaz-naberezhnye-chelny", name: "FK KAMAZ Naberezhnye Chelny" },
  { value: "kapfenberger-sv-kapfenberg", name: "Kapfenberger SV" },
  { value: "karacabey-belediyespor-bursa", name: "Karacabey Belediyespor AŞ" },
  { value: "karlsruher-sc-karlsruhe", name: "Karlsruher SC" },
  { value: "karmiotissa-pano-polemidia", name: "Karmiotissa Pano Polemidia" },
  { value: "kashima-antlers-kashima-ibaraki", name: "Kashima Antlers" },
  { value: "kashiwa-reysol-kashiwa-chiba", name: "Kashiwa Reysol" },
  { value: "kasimpasa-istanbul", name: "Kasımpaşa Spor Kulübü" },
  { value: "kasimpasa-res-istanbul", name: "Kasımpaşa Spor Kulübü Reserves" },
  { value: "kasimpasa-u19-istanbul", name: "Kasımpaşa Spor Kulübü Under 19" },
  { value: "kataller-toyama-toyama", name: "Kataller Toyama" },
  { value: "kawasaki-frontale-kawasaki", name: "Kawasaki Frontale" },
  { value: "kayserispor-kayseri", name: "Kayseri Spor Kulübü" },
  { value: "keciorengucu-ankara", name: "Keçiörengücü Spor Kulübü" },
  { value: "kestelspor", name: "Kestel Spor Kulübü" },
  { value: "kfum-oslo", name: "KFUM Fotball" },
  { value: "khimki-ii-khimki", name: "FK Khimki II" },
  { value: "khimki-khimki", name: "FK Khimki" },
  { value: "kilmarnock-kilmarnock", name: "Kilmarnock FC" },
  { value: "kirklarelispor-kirklareli", name: "Kırklareli Spor Kulübü" },
  { value: "kitchee-hong-kong", name: "Kitchee SC" },
  { value: "knokke-knokke-heist", name: "Royal Knokke FC" },
  { value: "kobenhavn-kobenhavn", name: "FC København" },
  { value: "kocaelispor-izmet", name: "Kocaelispor Kulübü" },
  { value: "koln-koln", name: "1. FC Köln" },
  { value: "kolos-kovalivka-kovalivka", name: "FK Kolos Kovalivka" },
  { value: "koninklijke-hfc-haarlem", name: "Koninklijke Haarlemsche FC" },
  { value: "konyaspor-konya", name: "Atiker Konyaspor Kulübü" },
  { value: "korona-kielce-kielce", name: "MKS Korona Kielce" },
  { value: "kortrijk-kortrijk", name: "KV Kortrijk" },
  { value: "krasnodar-krasnodar", name: "FK Krasnodar" },
  { value: "kristiansund-kristiansund", name: "Kristiansund BK" },
  { value: "krylya-sovetov-samara", name: "PFK Krylya Sovetov Samara" },
  { value: "kufstein-kufstein", name: "FC Kufstein" },
  { value: "kusadasispor-kusadasi", name: "Kuşadası Gençlik Spor Kulübü" },
  { value: "kv-oostende-oostende", name: "KV Oostende" },
  { value: "kyoto-sanga-kyoto-kyoto", name: "Kyoto Sanga FC" },
  { value: "la-galaxy-ii-carson-california", name: "Los Angeles" },
  { value: "la-galaxy-los-angeles-california", name: "LA Galaxy" },
  { value: "la-louviere-la-louviere", name: "RAAL La Louvière" },
  { value: "laci-lac", name: "KF Laçi" },
  { value: "lafnitz-lafnitz", name: "SV Lafnitz" },
  { value: "lahti-lahti", name: "FC Lahti" },
  { value: "lanus-lanus-provincia-de-buenos-aires", name: "CA Lanús" },
  {
    value: "las-palmas-ii-las-palmas-de-gran-canaria",
    name: "UD Las Palmas Atlético",
  },
  { value: "las-palmas-las-palmas-de-gran-canaria", name: "UD Las Palmas" },
  { value: "las-vegas-lights-las-vegas-nevada", name: "Las Vegas Lights FC" },
  { value: "lask-juniors-linz", name: "LASK Juniors" },
  { value: "lask-linz", name: "LASK" },
  { value: "lausanne-sport-lausanne", name: "FC Lausanne Sport" },
  { value: "laval-laval", name: "Stade Lavallois Mayenne FC" },
  { value: "lazio-formello", name: "SS Lazio" },
  { value: "ldu-quito-quito", name: "Liga Deportiva Universitaria de Quito" },
  { value: "le-havre-harfleur", name: "Le Havre AC" },
  { value: "le-mans-le-mans", name: "Le Mans FC" },
  { value: "le-puy-foot-le-puy-en-velay", name: "Le Puy Football 43 Auvergne" },
  { value: "lecce-lecce", name: "US Lecce" },
  { value: "lech-poznan-poznan", name: "KKS Lech Poznań" },
  { value: "lechia-gdansk-gdansk", name: "KS Lechia Gdańsk" },
  { value: "leeds-united-leeds-west-yorkshire", name: "Leeds United FC" },
  { value: "leganes-leganes", name: "CD Leganés" },
  { value: "legia-warszawa-warszawa", name: "KP Legia Warszawa" },
  { value: "leicester-city-leicester", name: "Leicester City FC" },
  { value: "lens-avion", name: "Racing Club de Lens" },
  { value: "leoben-leoben", name: "DSV Leoben" },
  { value: "leon-leon-de-los-aldamas", name: "Club León" },
  { value: "levante-valencia", name: "Levante UD" },
  { value: "levski-sofia-sofia", name: "PFC Levski Sofia" },
  { value: "libertad-asuncion", name: "Club Libertad" },
  { value: "liefering-salzburg", name: "FC Liefering" },
  { value: "liege-rocourt", name: "RFC de Liege" },
  { value: "liepaja-liepaja", name: "FK Liepāja" },
  { value: "lierse-kempenzonen-lier", name: "KSK Lierse Kempenzonen" },
  { value: "lille-ii-camphin-en-pevele", name: "Lille OSC Métropole II" },
  { value: "lille-villeneuve-d-ascq", name: "Lille OSC Métropole" },
  { value: "lillestrom-kjeller", name: "Lillestrøm SK" },
  { value: "liverpool-liverpool", name: "Liverpool FC" },
  { value: "livingston-livingston", name: "Livingston FC" },
  { value: "lks-lodz-lodz", name: "ŁKS Łódź" },
  { value: "llanera-posada-de-llanera", name: "UD Llanera" },
  { value: "lokomotiv-moskva-moskva", name: "FC Lokomotiv Moscow" },
  { value: "lokomotiv-plovdiv-plovdiv", name: "PFC Lokomotiv Plovdiv" },
  { value: "lokomotiva-zagreb-zagreb", name: "NK Lokomotiva Zagreb" },
  { value: "lommel-lommel", name: "Lommel SK" },
  { value: "londrina-londrina-parana", name: "Londrina EC" },
  { value: "lorient-ploemeur", name: "FC Lorient" },
  { value: "los-angeles-fc-los-angeles-california", name: "Los Angeles FC" },
  { value: "loudoun-united-leesburg-virginia", name: "Loudoun United FC" },
  { value: "louisville-city-louisville-kentucky", name: "Louisville City FC" },
  { value: "ludogorets-razgrad", name: "PFC Ludogorets 1945 Razgrad" },
  { value: "lugano-lugano", name: "FC Lugano" },
  { value: "lugo-lugo", name: "CD Lugo" },
  { value: "luton-town-luton-bedfordshire", name: "Luton Town FC" },
  { value: "luzern-luzern", name: "FC Luzern" },
  { value: "lyngby-lyngby-kongens-lyngby", name: "Lyngby Boldklub" },
  { value: "macara-ambato", name: "CSD Macará" },
  { value: "maccabi-haifa-hefa-haifa", name: "Maccabi Haifa FC" },
  {
    value: "maccabi-tel-aviv-tel-aviv-yafa-tel-aviv",
    name: "Maccabi Tel Aviv FC",
  },
  { value: "machida-zelvia-tokyo", name: "FC Machida Zelvia" },
  { value: "mafra-mafra", name: "CD Mafra" },
  { value: "magdeburg-magdeburg", name: "1. FC Magdeburg" },
  { value: "maghreb-fes-fes", name: "Maghreb AS de Fès" },
  { value: "mainz-05-ii-mainz", name: "1. FSV Mainz 05 II" },
  { value: "mainz-05-mainz", name: "1. FSV Mainz 05" },
  { value: "malaga-malaga", name: "Málaga CF" },
  { value: "mallorca-palma-de-mallorca", name: "Real Club Deportivo Mallorca" },
  { value: "malmo-ff-malmo", name: "Malmö FF" },
  { value: "manchester-city-manchester", name: "Manchester City FC" },
  { value: "manchester-city-u21-manchester", name: "Manchester City Under 21" },
  { value: "manchester-united-manchester", name: "Manchester United FC" },
  { value: "manchester-united-u21", name: "Manchester United Under 21" },
  {
    value: "manisa-bbsk-manisa",
    name: "Manisa Büyükşehir Belediye Spor Kulübü",
  },
  { value: "manta-manta", name: "Manta FC" },
  { value: "maribor-maribor", name: "NK Maribor" },
  { value: "maritimo-funchal-ilha-da-madeira", name: "CS Marítimo Funchal" },
  { value: "maruyasu-okazaki-nagoya", name: "FC Maruyasu Okazaki" },
  { value: "matsumoto-yamaga-matsumoto", name: "Matsumoto Yamaga FC" },
  { value: "mazatlan-mazatlan", name: "Mazatlán FC" },
  { value: "mc-alger-cheraga", name: "MC Alger" },
  { value: "mechelen-mechelen-malines", name: "YR KV Mechelen" },
  { value: "melbourne-city-melbourne", name: "Melbourne City FC" },
  { value: "melbourne-victory-melbourne", name: "Melbourne Victory FC" },
  { value: "memphis-901-memphis-tennessee", name: "Memphis 901 FC" },
  {
    value: "menemen-belediyespor-menemen",
    name: "Menemen Belediye Spor Kulübü",
  },
  { value: "messina-messina", name: "ACR Messina" },
  { value: "metropolitanos-caracas", name: "Metropolitanos FC" },
  { value: "metz-ii-metz", name: "FC Metz II" },
  { value: "metz-metz", name: "FC Metz" },
  { value: "mezokovesd-zsory-mezokovesd", name: "Mezőkövesd-Zsóry SE" },
  { value: "miami-fc-2-miami-florida", name: "Miami FC 2" },
  { value: "middlesbrough-middlesbrough", name: "Middlesbrough FC" },
  { value: "middlesbrough-u21-middlesbrough", name: "Middlesbrough Under 21" },
  { value: "midtjylland-herning", name: "FC Midtjylland" },
  { value: "miedz-legnica-legnica", name: "MKS Miedź Legnica" },
  { value: "milan-milano", name: "AC Milan" },
  { value: "millonarios-bogota-d-c", name: "Millonarios FC SA" },
  { value: "millwall-london", name: "Millwall FC" },
  {
    value: "milton-keynes-dons-milton-keynes-buckinghamshire",
    name: "Milton Keynes Dons FC",
  },
  {
    value: "minnesota-united-minneapolis-saint-paul-minnesota",
    name: "Minnesota United",
  },
  { value: "minsk-minsk", name: "FC Minsk" },
  { value: "mirandes-miranda-de-ebro", name: "CD Mirandés" },
  { value: "mirassol-mirassol-sao-paulo", name: "Mirassol Futebol Clube" },
  { value: "mito-hollyhock-mito", name: "Mito Hollyhock" },
  { value: "mladost-novi-sad-novi-sad", name: "FK Mladost Novi Sad" },
  { value: "modena-modena", name: "Modena FC" },
  { value: "mokpo-city-mokpo", name: "Mokpo City Government FC" },
  { value: "molde-molde", name: "Molde FK" },
  { value: "monaco-monaco", name: "AS Monaco FC" },
  { value: "monastir-monastir", name: "US Monastirienne" },
  { value: "monopoli-monopoli", name: "Monopoli Calcio 1966" },
  { value: "montagnarde-inzinzac", name: "US Montagnarde Inzinzac Lochrist" },
  { value: "montedio-yamagata-tendo-tendo", name: "Montedio Yamagata" },
  { value: "monterrey-monterrey", name: "CF Monterrey" },
  {
    value: "montevarchi-calcio-montevarchi",
    name: "Montevarchi Calcio Aquila 1902",
  },
  { value: "montpellier-montpellier", name: "Montpellier HSC" },
  { value: "montreal-impact-montreal-quebec", name: "CF Montreal" },
  { value: "montrose-montrose", name: "Montrose FC" },
  { value: "monza-monza", name: "AC Monza" },
  { value: "morecambe-morecambe-lancashire", name: "Morecambe FC" },
  { value: "moreirense-moreira-de-conegos", name: "Moreirense FC" },
  { value: "motherwell-motherwell", name: "Motherwell FC" },
  { value: "msv-duisburg-duisburg", name: "MSV Duisburg" },
  { value: "muang-thong-united-bangkok", name: "SCG Muang Thong United FC" },
  { value: "municipal-ciudad-de-guatemala", name: "CSD Municipal" },
  { value: "mus-menderesspor-mus", name: "Muş Menderesspor" },
  { value: "mvv-maastricht", name: "MVV" },
  { value: "nac-breda-breda", name: "NAC Breda" },
  { value: "nacional-montevideo", name: "Club Nacional de Football" },
  { value: "nagoya-grampus-nagoya", name: "Nagoya Grampus" },
  {
    value: "nakhon-si-thammarat-nakhon-si-thammarat",
    name: "Nakhon Si Thammarat Unity",
  },
  { value: "nancy-nancy", name: "AS Nancy-Lorraine" },
  { value: "nantes-ii-la-chapelle-sur-erdre", name: "FC Nantes II" },
  { value: "nantes-la-chapelle-sur-erdre", name: "FC Nantes" },
  { value: "napoli-castel-volturno", name: "SSC Napoli" },
  { value: "napredak-krusevac", name: "FK Napredak Kruševac" },
  { value: "nashville-sc", name: "Nashville SC" },
  { value: "nassaji-mazandaran-mazandaran", name: "Nassaji Mazandaran" },
  { value: "navbahor-namangan", name: "FK Navbahor Namangan" },
  { value: "nazilli-belediyespor-aydin", name: "Nazilli Belediye Spor Kulübü" },
  { value: "nea-salamis-larnaka-larnaca", name: "Nea Salamis Famagusta" },
  { value: "nec-nijmegen", name: "Nijmegen Eendracht Combinatie" },
  { value: "necaxa-aguascalientes", name: "Club Necaxa" },
  { value: "neftci-baki-baku", name: "Neftçi PFC Bakı" },
  { value: "neftekhimik-nizhnekamsk", name: "FK Neftekhimik Nizhnekamsk" },
  { value: "neuchatel-xamax-bevaix", name: "Neuchâtel Xamax FCS" },
  {
    value: "new-england-foxborough-massachusetts",
    name: "New England Revolution",
  },
  { value: "new-england-ii", name: "New England" },
  {
    value: "new-mexico-united-albuquerque-new-mexico",
    name: "New Mexico United",
  },
  { value: "new-york-city-new-york-new-york", name: "New York City FC" },
  { value: "new-york-rb-secaucus-new-jersey", name: "New York Red Bulls" },
  { value: "newcastle-jets-newcastle-west", name: "Newcastle Jets FC" },
  {
    value: "newcastle-united-newcastle-upon-tyne",
    name: "Newcastle United FC",
  },
  {
    value: "newcastle-united-u21-newcastle",
    name: "Newcastle United Under 21",
  },
  {
    value: "newcastle-united-u23-newcastle-upon-tyne",
    name: "Newcastle United Under 23",
  },
  { value: "newell-s-old-boys-rosario-santa-fe", name: "Newell's Old Boys" },
  { value: "newport-county-newport-casnewydd", name: "Newport County AFC" },
  { value: "nice-nice", name: "OGC Nice Côte d'Azur" },
  { value: "nieciecza-zabno", name: "LKS Termalica Bruk-Bet Nieciecza" },
  { value: "nimes-nimes", name: "Nîmes Olympique" },
  { value: "niort-niort", name: "Chamois Niortais FC" },
  { value: "nizhny-novgorod-nizhny-novgorod", name: "FK Nizhny Novgorod" },
  { value: "nordsjaelland-farum", name: "FC Nordsjælland" },
  { value: "norrkoping-norrkoping", name: "IFK Norrköping" },
  { value: "north-carolina-cary-north-carolina", name: "North Carolina FC" },
  { value: "northeast-united-guwahati", name: "NorthEast United FC" },
  { value: "norwich-city-norwich-norfolk", name: "Norwich City FC" },
  { value: "nottingham-forest-nottingham", name: "Nottingham Forest FC" },
  {
    value: "nottingham-forest-u21-nottingham",
    name: "Nottingham Forest Under 21",
  },
  {
    value: "nottingham-forest-u23-nottingham",
    name: "Nottingham Forest Under 23",
  },
  { value: "novi-sad-novi-sad", name: "FK Novi Sad" },
  {
    value: "novorizontino-novo-horizonte-sao-paulo",
    name: "Grêmio Novorizontino",
  },
  { value: "nurnberg-nurnberg", name: "1. FC Nürnberg" },
  { value: "oakland-roots", name: "Oakland Roots SC" },
  { value: "ob-odense", name: "Odense BK" },
  { value: "odd-skien", name: "Odds BK" },
  { value: "ofi-iraklio-heraklion", name: "OFI FC" },
  { value: "oh-leuven-heverlee", name: "Oud-Heverlee Leuven" },
  { value: "oita-trinita-oita-oita", name: "Oita Trinita" },
  { value: "olimpia-tegucigalpa", name: "CD Olimpia" },
  { value: "olimpija-ljubljana", name: "NK Olimpija Ljubljana" },
  { value: "olympiakos-nicosia-levkosia", name: "Olympiakos Nicosia FC" },
  { value: "olympiakos-piraeus-ii-pireas-piraeus", name: "Olympiakos CFP II" },
  { value: "olympiakos-piraeus-pireas-piraeus", name: "Olympiakos CFP" },
  { value: "olympique-lyonnais-lyon", name: "Olympique Lyonnais" },
  {
    value: "olympique-marseille-ii-marseille-cedex-12",
    name: "Olympique de Marseille II",
  },
  { value: "olympique-marseille-marseille", name: "Olympique de Marseille" },
  {
    value: "olympique-marseille-u19-marseille",
    name: "Olympique de Marseille Under 19",
  },
  { value: "omiya-ardija-saitama", name: "Omiya Ardija" },
  { value: "omonia-nicosia-nicosia-levkosia", name: "AC Omonia Nicosia" },
  { value: "orange-county-sc-costa-mesa-california", name: "Orange County SC" },
  { value: "orenburg-orenburg", name: "FK Orenburg" },
  {
    value: "oriente-petrolero-santa-cruz-de-la-sierra",
    name: "CD Oriente Petrolero",
  },
  { value: "orlando-city-lake-mary-florida", name: "Orlando City SC" },
  { value: "orleans-ii-orleans", name: "US Orléans Loiret II" },
  { value: "osaka", name: "FC Osaka" },
  { value: "osasuna-ii-pamplona", name: "CA Osasuna Promesas" },
  { value: "osasuna-pamplona-irunea", name: "CA Osasuna" },
  { value: "osijek-osijek", name: "NK Osijek" },
  { value: "osnabruck-osnabruck", name: "VfL Osnabrück" },
  { value: "oud-heverlee-leuven-ii-heverlee", name: "Oud-Heverlee Leuven B" },
  { value: "oxford-united-oxford-oxfordshire", name: "Oxford United FC" },
  { value: "pachuca-pachuca-de-soto", name: "CF Pachuca" },
  { value: "pacific-411d621a-5bbf-40ea-948d-a0c2e8af88c5", name: "Pacific FC" },
  {
    value: "pacos-de-ferreira-pacos-de-ferreira",
    name: "FC Paços de Ferreira",
  },
  { value: "paderborn-paderborn", name: "SC Paderborn 07" },
  { value: "paide-tallinn", name: "Paide Linnameeskond" },
  { value: "paju-citizen-paju", name: "Paju Citizen FC" },
  { value: "pakhtakor-toshkent-tashkent", name: "FK Pakhtakor Tashkent" },
  { value: "palermo-palermo", name: "US Città di Palermo" },
  { value: "palestino-santiago-de-chile", name: "CD Palestino" },
  { value: "palmeiras-sao-paulo-sao-paulo", name: "SE Palmeiras" },
  { value: "panaitolikos-agrinio", name: "Panaitolikos GFS Agrinio" },
  { value: "panathinaikos-athina-athens", name: "Panathinaikos FC" },
  { value: "paok-thessaloniki", name: "PAOK Thessaloniki FC" },
  { value: "paphos-paphos", name: "Paphos FC" },
  { value: "parceiro-nagano-nagano", name: "AC Parceiro Nagano" },
  { value: "paris-paris", name: "Paris FC" },
  { value: "parma-parma", name: "Parma Calcio 1913" },
  { value: "partick-thistle-glasgow", name: "Partick Thistle FC" },
  { value: "partizan-beograd", name: "FK Partizan Beograd" },
  { value: "patro-eisden-maasmechelen", name: "Patro Eisden Maasmechelen" },
  {
    value: "patronato-parana-provincia-de-entre-rios",
    name: "CA Patronato de la Juventud Católica",
  },
  { value: "pau-pau", name: "Pau FC" },
  { value: "paysandu-belem-para", name: "Paysandu SC" },
  { value: "pec-zwolle-zwolle", name: "PEC Zwolle" },
  { value: "penarol-montevideo", name: "CA Peñarol" },
  { value: "pendikspor-istanbul", name: "Pendik Spor Kulübü" },
  { value: "pergolettese-crema", name: "US Pergolettese 1932" },
  { value: "persepolis-teheran", name: "Persepolis FC" },
  { value: "persija-jakarta", name: "Persatuan Sepak Bola Indonesia Jakarta" },
  { value: "perth-glory-perth-1995", name: "Perth Glory FC" },
  { value: "perugia-perugia", name: "Perugia Calcio" },
  { value: "pescara-pescara", name: "Delfino Pescara 1936" },
  { value: "peterhead-peterhead", name: "Peterhead FC" },
  {
    value: "philadelphia-union-chester-pennsylvania",
    name: "Philadelphia Union",
  },
  { value: "phoenix-rising-phoenix-arizona", name: "Phoenix Rising FC" },
  { value: "piast-gliwice-gliwice", name: "GKS Piast Gliwice" },
  { value: "pisa-pisa", name: "AC Pisa 1909" },
  {
    value: "pittsburgh-riverhounds-pittsburgh-pennsylvania",
    name: "Pittsburgh Riverhounds FC",
  },
  {
    value: "platense-vicente-lopez-provincia-de-buenos-aires",
    name: "CA Platense",
  },
  { value: "plymouth-argyle-plymouth", name: "Plymouth Argyle FC" },
  { value: "pocheon-pocheon", name: "Pocheon FC" },
  { value: "podbrezova-podbrezova", name: "ŽP Šport Podbrezová" },
  { value: "pogon-szczecin-szczecin", name: "MKS Pogoń Szczecin" },
  { value: "pohang-steelers-pohang", name: "Pohang Steelers FC" },
  { value: "politehnica-ia-i-iasi", name: "FC Politehnica Iași" },
  { value: "ponferradina-ponferrada", name: "SD Ponferradina" },
  {
    value: "ponte-preta-campinas-sao-paulo",
    name: "Associacao Atletica Ponte Preta",
  },
  { value: "port-bangkok", name: "Port MTI FC" },
  { value: "port-vale-stoke-on-trent-staffordshire", name: "Port Vale FC" },
  { value: "portimonense-portimao", name: "Portimonense SC" },
  { value: "portland-timbers-portland-oregon", name: "Portland Timbers" },
  { value: "porto-ii-porto", name: "Porto II" },
  { value: "porto-porto", name: "FC Porto" },
  { value: "portsmouth-portsmouth", name: "Portsmouth FC" },
  { value: "potenza-calcio-potenza", name: "SSD Potenza Calcio" },
  { value: "prat-el-prat-de-llobregat", name: "AE Prat" },
  { value: "preston-north-end-preston", name: "Preston North End FC" },
  { value: "preussen-munster-munster", name: "SC Preußen 06 Münster" },
  { value: "psg-paris", name: "Paris Saint-Germain FC" },
  { value: "psv-eindhoven", name: "PSV Eindhoven" },
  { value: "psv-ii-eindhoven", name: "PSV Eindhoven II" },
  { value: "psv-u21", name: "PSV Eindhoven Under 21" },
  { value: "puebla-puebla", name: "Puebla FC" },
  {
    value: "pumas-unam-delegacion-coyoacan-ciudad-de-mexico-d-f",
    name: "Pumas de la Universidad Nacional Autonoma de Mexico",
  },
  { value: "puntarenas-puntarenas", name: "Puntarenas FC" },
  { value: "puskas-felcsut", name: "Puskás Ferenc Akadémia FC" },
  { value: "pyeongtaek-citizen-pyeongtaek", name: "Pyeongtaek Citizen FC" },
  { value: "pyramids-asyut", name: "Pyramids FC" },
  { value: "pyunik-yerevan", name: "Pyunik FC" },
  { value: "qatar-sc-ad-doha-doha", name: "Qatar SC" },
  { value: "qizilqum-zarafshon", name: "Qizilqum Zarafshon PFK" },
  { value: "queen-of-the-south-dumfries", name: "Queen of the South FC" },
  { value: "queen-s-park-glasgow", name: "Queen's Park FC" },
  { value: "queens-park-rangers-london", name: "Queens Park Rangers FC" },
  { value: "queretaro-queretaro", name: "Querétaro FC" },
  { value: "queretaro-u20", name: "Querétaro Under 20" },
  {
    value: "quevilly-rouen-le-petit-quevilly",
    name: "US Quevilly Rouen Metropole",
  },
  { value: "quick-boys-katwijk", name: "kvv Quick Boys" },
  { value: "racing-besancon-besancon-1904", name: "Racing Besançon" },
  {
    value: "racing-club-avellaneda-provincia-de-buenos-aires",
    name: "Racing Club de Avellaneda",
  },
  {
    value: "racing-santander-santander",
    name: "Real Racing Club de Santander",
  },
  {
    value: "radnicki-kragujevac-kragujevac",
    name: "FK Radnički 1923 Kragujevac",
  },
  { value: "radnicki-nis-nis", name: "FK Radnički Niš" },
  { value: "radomiak-radom-radom", name: "RKS Radomiak Radom" },
  {
    value: "raja-casablanca-casablanca",
    name: "Raja Club Athletic de Casablanca",
  },
  { value: "rakow-czestochowa-czestochowa", name: "KS Raków Częstochowa" },
  { value: "randers-randers", name: "Randers FC" },
  { value: "rangers-glasgow", name: "Rangers FC" },
  { value: "rapid-bucuresti-bucuresti", name: "Fotbal Club Rapid" },
  { value: "rapid-wien-ii-wien", name: "SK Rapid Wien II" },
  { value: "rapid-wien-wien", name: "SK Rapid Wien" },
  { value: "rayo-vallecano-madrid", name: "Rayo Vallecano" },
  { value: "rb-leipzig-leipzig", name: "RB Leipzig" },
  { value: "reading-reading-berkshire", name: "Reading FC" },
  { value: "real-betis-sevilla", name: "Real Betis Balompié" },
  { value: "real-madrid-madrid", name: "Real Madrid CF" },
  {
    value: "real-madrid-u19-madrid",
    name: "Real Madrid Club de Fútbol Under 19",
  },
  { value: "real-monarchs-sandy", name: "Real Monarchs SLC" },
  { value: "real-oviedo-oviedo", name: "Real Oviedo" },
  { value: "real-salt-lake-salt-lake-city-utah", name: "Real Salt Lake" },
  {
    value: "real-sociedad-donostia-san-sebastian",
    name: "Real Sociedad de Fútbol",
  },
  {
    value: "real-valladolid-valladolid",
    name: "Real Valladolid Club de Fútbol",
  },
  {
    value: "real-zaragoza-ii-zaragoza",
    name: "Real Zaragoza Deportivo Aragón",
  },
  { value: "real-zaragoza-zaragoza", name: "Real Zaragoza" },
  { value: "red-star-saint-ouen", name: "Red Star FC 93" },
  { value: "reggina-reggio-calabria", name: "Urbs Sportiva Reggina 1914" },
  { value: "reims-reims", name: "Stade de Reims" },
  { value: "reinmeer-aomori-aomori", name: "Reinmeer Aomori FC" },
  { value: "rennes-ii-rennes", name: "Stade Rennais FC II" },
  { value: "rennes-rennes", name: "Stade Rennais F.C." },
  { value: "renofa-yamaguchi", name: "Renofa Yamaguchi" },
  { value: "rentistas-montevideo", name: "Club Atlético Rentistas" },
  { value: "retired", name: "" },
  { value: "rfc-seraing-seraing", name: "RFC Seraing" },
  { value: "rheindorf-altach-altach", name: "CASHPOINT SCR Altach" },
  { value: "ried-ried-im-innkreis", name: "SV Guntamatic Ried" },
  { value: "riga-riga", name: "Riga FC" },
  { value: "rijeka-rijeka", name: "HNK Rijeka" },
  { value: "rimini-rimini", name: "AC Rimini" },
  { value: "rio-ave-vila-do-conde", name: "Rio Ave FC" },
  {
    value: "rio-grande-valley-edinburg-texas",
    name: "Rio Grande Valley FC Toros",
  },
  { value: "rionegro-rionegro", name: "Águilas Doradas Rionegro SA" },
  {
    value: "river-plate-capital-federal-ciudad-de-buenos-aires",
    name: "CA River Plate",
  },
  { value: "rizespor-rize", name: "Çaykur Rize Spor Kulübü" },
  { value: "rkc-waalwijk-waalwijk", name: "RKC Waalwijk" },
  { value: "roasso-kumamoto-kumamoto", name: "Roasso Kumamoto" },
  { value: "rochester-rhinos-rochester-new-york", name: "Rochester Rhinos" },
  { value: "roda-jc-kerkrade", name: "SV Roda JC" },
  { value: "rodez-onet-le-chateau", name: "Rodez Aveyron Football" },
  { value: "rodina-moskva", name: "FK Rodina Moskva" },
  { value: "roma-roma", name: "AS Roma" },
  {
    value: "rosario-central-rosario-provincia-de-santa-fe",
    name: "CA Rosario Central",
  },
  { value: "rosenborg-trondheim", name: "Rosenborg BK" },
  { value: "ross-county-dingwall", name: "Ross County FC" },
  { value: "rostov-rostov-na-donu", name: "FK Rostov" },
  { value: "rot-weiss-essen-essen", name: "Rot-Weiss Essen" },
  { value: "rotenberg-lingenau", name: "FC Rotenberg" },
  { value: "rotherham-united-rotherham", name: "Rotherham United FC" },
  { value: "rotor-volgograd-volgograd", name: "FK Rotor Volgograd" },
  { value: "royal-antwerp-ii-deurne", name: "Royal Antwerp B" },
  {
    value: "royal-excel-mouscron-mouscron-moeskroen",
    name: "Royal Excel Mouscron",
  },
  {
    value: "rsc-anderlecht-ii-bruxelles-brussel",
    name: "RSC Anderlecht U23 (RSC Anderlecht II)",
  },
  { value: "rsc-internacional-madrid", name: "RSC Internacional FC" },
  { value: "rubin-kazan-kazan", name: "FK Rubin Kazan" },
  { value: "rupel-boom-boom", name: "Rupel Boom FC" },
  {
    value: "rwdm-brussels-molenbeek-saint-jean",
    name: "Racing White Daring Molenbeek 47",
  },
  { value: "ryazan-ryazan", name: "FK Ryazan" },
  { value: "ryukyu-naha", name: "FC Ryūkyū" },
  {
    value: "sacramento-republic-sacramento-california",
    name: "Sacramento Republic FC",
  },
  { value: "sagamihara-sagamihara", name: "SC Sagamihara" },
  { value: "sagan-tosu-tosu", name: "Sagan Tosu" },
  { value: "saint-etienne-ii-l-etrat", name: "AS Saint-Étienne II" },
  { value: "saint-etienne-saint-etienne", name: "AS Saint-Étienne" },
  { value: "saint-priest-saint-priest", name: "AS Saint-Priest" },
  { value: "sakaryaspor-adapazari", name: "Sakaryaspor Kulübü Derneği" },
  { value: "salernitana-salerno", name: "US Salernitana 1919" },
  { value: "salford-city-salford-greater-manchester", name: "Salford City FC" },
  { value: "salzburg-wals-siezenheim", name: "FC Red Bull Salzburg" },
  { value: "sampdoria-genova", name: "UC Sampdoria" },
  { value: "samsunspor-samsun", name: "Samsunspor Kulübü" },
  { value: "san-antonio-san-antonio-texas", name: "San Antonio FC" },
  { value: "san-carlos-ciudad-quesada", name: "AD San Carlos" },
  { value: "san-diego-san-diego", name: "San Diego" },
  { value: "san-diego-loyal", name: "San Diego Loyal SC" },
  {
    value: "san-lorenzo-capital-federal-ciudad-de-buenos-aires",
    name: "CA San Lorenzo de Almagro",
  },
  { value: "san-luis-quillota", name: "CD San Luis de Quillota" },
  {
    value: "san-martin-san-juan-san-juan-provincia-de-san-juan",
    name: "CA San Martín de San Juan",
  },
  {
    value: "san-martin-tucuman-san-miguel-de-tucuman-provincia-de-tucuman",
    name: "CA San Martín de Tucumán",
  },
  { value: "sandefjord-sandefjord", name: "Sandefjord Fotball" },
  { value: "sandhausen-sandhausen", name: "SV Sandhausen" },
  { value: "sanfrecce-hiroshima-hiroshima", name: "Sanfrecce Hiroshima" },
  { value: "sangju-sangmu-sangju", name: "Gimcheon Sangmu" },
  {
    value: "santa-clara-ponta-delgada-ilha-de-sao-miguel-acores",
    name: "CD Santa Clara",
  },
  { value: "santa-fe-bogota-d-c", name: "Independiente Santa Fe SA" },
  { value: "santa-lucia-santa-lucija", name: "Santa Lucia FC" },
  { value: "santos-laguna-torreon", name: "Club Santos Laguna" },
  { value: "santos-santos-sao-paulo", name: "Santos FC Sao Paulo" },
  { value: "sao-bento-sorocaba-sao-paulo", name: "EC São Bento" },
  { value: "sao-paulo-sao-paulo-sao-paulo", name: "São Paulo FC" },
  { value: "sapancaspor-sapanca", name: "Sapanca Spor Kulübü" },
  { value: "sarajevo-sarajevo", name: "FK Sarajevo" },
  { value: "sarmiento-junin-provincia-de-buenos-aires", name: "CA Sarmiento" },
  { value: "sarpsborg-08-sarpsborg", name: "Sarpsborg 08 FF" },
  { value: "sassuolo-sassuolo", name: "US Sassuolo Calcio" },
  { value: "schalke-04-gelsenkirchen", name: "FC Schalke 04" },
  { value: "scheveningen-den-haag", name: "SVV Scheveningen" },
  { value: "schwarz-weiss-bregenz-bregenz", name: "Schwarz-Weiß Bregenz" },
  { value: "seattle-sounders-renton-washington", name: "Seattle Sounders FC" },
  { value: "sektzia-nes-tziona-nes-tziona", name: "Sektzia Nes Tziona FC" },
  { value: "seongnam-seongnam", name: "Seongnam FC" },
  { value: "seoul-e-land-seoul", name: "Seoul E-Land FC" },
  { value: "seoul-seoul", name: "FC Seoul" },
  { value: "sepahan-esfahan-isfahan", name: "Foolad Mobarakeh Sepahan SC" },
  { value: "sepsi-sfantu-gheorghe", name: "Sepsi OSK Sfântu Gheorghe" },
  { value: "servette-carouge", name: "Servette FC" },
  { value: "sestao-river-sestao", name: "Sestao River Club" },
  { value: "sevilla-ii-sevilla", name: "Sevilla Atlético" },
  { value: "sevilla-sevilla-1890", name: "Sevilla FC" },
  {
    value: "shabab-al-ahli-dubai-dubayy-dubai",
    name: "Shabab Al Ahli Dubai Club",
  },
  { value: "shakhtar-donetsk-donets-k", name: "FC Shakhtar Donetsk" },
  {
    value: "shakhter-karagandy-qaragandy-karaganda",
    name: "FK Shakhter Karagandy",
  },
  { value: "shakhtyor-salihorsk-soligorsk", name: "FC Shakhtyor Soligorsk" },
  { value: "shandong-luneng-ji-nan", name: "Shandong Luneng Taishan FC" },
  { value: "shanghai-shenhua-shanghai", name: "Shanghai Shenhua FC" },
  { value: "shanghai-sipg-shanghai", name: "Shanghai SIPG FC" },
  { value: "sheffield-united-sheffield", name: "Sheffield United FC" },
  { value: "shenzhen-shenzhen", name: "Shenzhen FC" },
  { value: "sheriff-tiraspol", name: "FK Sheriff Tiraspol" },
  { value: "shimizu-s-pulse-shizuoka", name: "Shimizu S-Pulse" },
  { value: "shinnik-yaroslavl", name: "FK Shinnik Yaroslavl" },
  { value: "shonan-bellmare-hiratsuka", name: "Shonan Bellmare" },
  { value: "sibenik-sibenik", name: "HNK Šibenik" },
  { value: "sigma-olomouc-olomouc", name: "SK Sigma Olomouc" },
  { value: "siheung-citizen-siheung", name: "Siheung City Athletic Club" },
  { value: "silkeborg-silkeborg", name: "Silkeborg IF" },
  { value: "sint-truiden-sint-truiden-st-trond", name: "Sint-Truidense VV" },
  { value: "sion-martigny-croix", name: "FC Sion" },
  { value: "sirens", name: "Sirens" },
  { value: "sivas-belediyespor-sivas", name: "Sivas Belediye Spor Kulübü" },
  { value: "sivasspor-sivas", name: "Sivasspor Kulübü" },
  {
    value: "sj-earthquakes-santa-clara-california",
    name: "San Jose Earthquakes",
  },
  { value: "ska-khabarovsk-khabarovsk", name: "FK SKA-Khabarovsk" },
  { value: "ska-rostov-rostov-na-donu", name: "SKA Rostov-na-Donu" },
  { value: "slask-wroclaw-wroclaw", name: "WKS Śląsk Wrocław" },
  {
    value: "slaven-koprivnica-koprivnica",
    name: "NK Slaven Belupo Koprivnica",
  },
  { value: "slovacko-uherske-hradiste", name: "1. FC Slovácko" },
  { value: "slovan-bratislava-bratislava", name: "ŠK Slovan Bratislava" },
  { value: "slovan-bratislava-ii-bratislava", name: "ŠK Slovan Bratislava II" },
  { value: "sochaux-montbeliard", name: "FC Sochaux-Montbéliard" },
  { value: "sochi-sochi", name: "FK Sochi" },
  { value: "sokol-saratov-saratov", name: "FK Sokol Saratov" },
  { value: "sol-de-america-asuncion", name: "Club Sol de América" },
  { value: "somaspor-soma", name: "Soma Spor Kulübü" },
  { value: "southampton-southampton-hampshire", name: "Southampton FC" },
  { value: "southampton-u21-southampton", name: "Southampton Under 21" },
  { value: "spakenburg-bunschoten", name: "SV Spakenburg" },
  { value: "spal-ferrara", name: "Società Polisportiva Ars et Labor 2013" },
  { value: "sparta-praha-praha", name: "AC Sparta Praha" },
  { value: "sparta-rotterdam-ii-rotterdam", name: "Sparta Rotterdam II" },
  { value: "sparta-rotterdam-rotterdam", name: "Sparta Rotterdam" },
  { value: "spartak-moskva-moskva", name: "FK Spartak Moskva" },
  { value: "spezia-la-spezia", name: "Spezia Calcio" },
  { value: "sport-recife-recife-pernambuco", name: "SC do Recife" },
  { value: "sporting-braga-braga", name: "Sporting Braga" },
  { value: "sporting-braga-ii-braga", name: "Sporting Braga II" },
  { value: "sporting-braga-u21-braga", name: "Sporting Braga U21" },
  {
    value: "sporting-charleroi-charleroi",
    name: "Sporting du Pays de Charleroi",
  },
  { value: "sporting-charleroi-ii-charleroi", name: "Royal Charleroi SC B" },
  { value: "sporting-cp-lisboa", name: "Sporting Clube de Portugal" },
  { value: "sporting-cristal-lima", name: "Club Sporting Cristal SAC" },
  { value: "sporting-gijon-gijon", name: "Real Sporting de Gijón" },
  { value: "sporting-kc-kansas-city-kansas", name: "Sporting Kansas City " },
  { value: "spouwen-mopertingen-mopertingen", name: "Spouwen-Mopertingen" },
  { value: "ssc-farul-constanta", name: "SSC Farul Constanţa" },
  { value: "st-gallen-st-gallen", name: "FC Sankt Gallen 1879" },
  { value: "st-johnstone-perth", name: "Saint Johnstone FC" },
  {
    value: "st-louis-city-st-louis-missouri",
    name: "St. Louis City SC",
  },
  { value: "st-louis-city-ii-st-louis-missouri", name: "St. Louis City SC II" },
  { value: "st-mirren-paisley", name: "Saint Mirren FC" },
  { value: "st-pauli-hamburg", name: "FC St. Pauli" },
  { value: "st-polten-sankt-polten", name: "SKN Sankt Pölten" },
  { value: "stabaek-bekkestua", name: "Stabæk Fotball" },
  { value: "stade-briochin-saint-brieuc", name: "Stade Briochin" },
  { value: "stade-lausanne-ouchy-lausanne", name: "FC Stade Lausanne-Ouchy" },
  {
    value: "standard-liege-ii-liege-luik",
    name: "Royal Standard de Liège U23 (Royal Standard de Liège II)",
  },
  { value: "standard-liege-liege-luik", name: "Royal Standard de Liège" },
  { value: "start-kristiansand", name: "IK Start" },
  { value: "stellenbosch-stellenbosch-wc", name: "Stellenbosch FC" },
  { value: "stenhousemuir-stenhousemuir", name: "Stenhousemuir FC" },
  { value: "stevenage-stevenage-hertfordshire", name: "Stevenage FC" },
  { value: "stoke-city-stoke-on-trent", name: "Stoke City FC" },
  { value: "straelen-straelen", name: "SV 1919 Straelen" },
  { value: "strasbourg-ii-strasbourg", name: "RC Strasbourg Alsace II" },
  { value: "strasbourg-strasbourg", name: "RC Strasbourg Alsace" },
  { value: "stripfing-stripfing", name: "SV Stripfing / Weiden" },
  { value: "sturm-graz-graz", name: "SK Puntigamer Sturm Graz" },
  { value: "stuttgart-ii-stuttgart", name: "VfB Stuttgart 1893 II" },
  { value: "stuttgart-stuttgart", name: "VfB Stuttgart" },
  { value: "sudtirol-bolzano-brixen", name: "FC Südtirol" },
  { value: "sunderland-sunderland", name: "Sunderland AFC" },
  { value: "sunderland-u21-sunderland", name: "Sunderland Under 21" },
  { value: "suwon-bluewings-suwon", name: "Suwon Samsung Bluewings FC" },
  { value: "suwon-suwon", name: "Suwon FC" },
  { value: "suzuka-unlimited-suzuka", name: "Suzuka Unlimited FC" },
  { value: "swansea-city-swansea", name: "Swansea City AFC" },
  {
    value: "swift-hesperange-betten-op-der-mess-bettange-sur-mess",
    name: "FC Swift Hesperange",
  },
  { value: "swindon-town-swindon-wiltshire", name: "Swindon Town FC" },
  {
    value: "swope-park-rangers-kansas-city-missouri",
    name: "Swope Park Rangers KC",
  },
  {
    value: "talleres-cordoba-ciudad-de-cordoba-provincia-de-cordoba",
    name: "CA Talleres de Córdoba",
  },
  {
    value: "tampa-bay-rowdies-st-petersburg-florida",
    name: "Tampa Bay Rowdies",
  },
  { value: "tampico-madero-ciudad-madero", name: "Tampico Madero FC" },
  { value: "taranto-taranto", name: "Taranto FC 1927" },
  { value: "tec-tiel", name: "sv Tielse Eendracht Combinatie" },
  { value: "telstar-velsen-zuid", name: "SC Telstar" },
  { value: "tenerife-santa-cruz-de-tenerife", name: "CD Tenerife" },
  {
    value: "tepatitlan-de-morelos-tepatitlan-de-morelos",
    name: "Tepatitlán de Morelos",
  },
  { value: "teplice-teplice", name: "FK Teplice" },
  { value: "ternana-terni", name: "Unicusano Ternana Calcio" },
  { value: "teutonia-pohlheim", name: "SC Teutonia Watzenborn-Steinberg" },
  {
    value: "the-new-saints-oswestry-croesoswallt-shropshire",
    name: "The New Saints FC",
  },
  { value: "thes-sport-tessenderlo", name: "KVV Thes Sport Tessenderlo" },
  { value: "thespakusatsu-gunma-maebashi", name: "ThespaKusatsu Gunma" },
  { value: "thun-thun", name: "FC Thun" },
  { value: "tianjin-teda-tianjin", name: "Tianjin Teda FC" },
  { value: "tienen-tienen-tirlemont", name: "KVK Tienen" },
  { value: "tigre-victoria-provincia-de-buenos-aires", name: "CA Tigre" },
  {
    value: "tigres-uanl-san-nicolas-de-los-garza",
    name: "CF Tigres de la Universidad Autónoma de Nuevo León",
  },
  { value: "tigres-zipaquira", name: "Tigres FC" },
  {
    value: "tijuana-tijuana",
    name: "Club Tijuana Xoloitzcuintles de Caliente",
  },
  { value: "tire-1922-spor-tire", name: "Tire 1922 Spor Kulübü" },
  { value: "tlaxcala", name: "Tlaxcala FC" },
  { value: "tobol-kostanai-kustanay-kostanay", name: "FK Tobol Kostanay" },
  { value: "tochigi-city-tochigi", name: "Tochigi City FC" },
  { value: "tochigi-utsunomiya", name: "Tochigi SC" },
  { value: "tokushima-vortis-tokushima", name: "Tokushima Vortis" },
  { value: "tokyo-tokyo", name: "FC Tokyo" },
  { value: "tokyo-verdy-tokyo", name: "Tokyo Verdy" },
  { value: "toluca-toluca-de-lerdo", name: "Deportivo Toluca FC" },
  { value: "top-oss-oss", name: "TOP Oss" },
  { value: "torino-torino", name: "Torino FC" },
  { value: "toronto-toronto", name: "Toronto FC" },
  { value: "torpedo-moskva-moskva", name: "FK Torpedo Moskva" },
  { value: "tottenham-hotspur-london", name: "Tottenham Hotspur FC" },
  { value: "tottenham-hotspur-u21", name: "Tottenham Hotspur Under 21" },
  { value: "toulouse-toulouse", name: "Toulouse FC" },
  { value: "trabzonspor-trabzon", name: "Trabzonspor Kulübü" },
  { value: "trencin-trencin", name: "FK AS Trenčín" },
  { value: "trento-trento", name: "AC Trento SCSD" },
  { value: "troyes-troyes", name: "Espérance Sportive Troyes Aube Champagne" },
  { value: "tucson-tucson-arizona", name: "FC Tucson" },
  { value: "tulsa-roughnecks-tulsa", name: "Tulsa Roughnecks FC" },
  { value: "tuzla-city-simin-han", name: "FK Tuzla City" },
  { value: "tuzlaspor-istanbul", name: "Tuzla Spor Kulübü" },
  { value: "twente-enschede", name: "FC Twente" },
  { value: "tyumen-tyumen", name: "FK Tyumen" },
  { value: "u-craiova-1948-craiova", name: "FC U Craiova 1948 SA" },
  {
    value: "uai-urquiza-general-san-martin-provincia-de-buenos-aires",
    name: "CD UAI Urquiza",
  },
  { value: "ud-ibiza-ibiza-town", name: "UD Ibiza" },
  { value: "ud-logrones-logrono", name: "UD Logroñés" },
  { value: "udinese-udine", name: "Udinese Calcio" },
  { value: "ufa-ufa", name: "FK Ufa" },
  { value: "ujpest-budapest", name: "Újpest FC" },
  { value: "ulsan-citizen-ulsan", name: "Ulsan Citizen FC" },
  { value: "ulsan-ulsan", name: "Ulsan Hyundai Horang-i" },
  { value: "umm-salal-umm-salal", name: "Umm Salal SC" },
  { value: "umraniyespor-istanbul", name: "Ümraniye Spor Kulübü" },
  { value: "una-zeelst", name: "vv UNA Zeelst" },
  { value: "union-adarve", name: "AD Unión Adarve Barrio del Pilar" },
  { value: "union-berlin-berlin", name: "1. FC Union Berlin" },
  { value: "union-comercio-nueva-cajamarca", name: "CD Unión Comercio" },
  { value: "union-espanola-santiago-de-chile", name: "Unión Española" },
  { value: "union-huaral-huaral", name: "Club Unión Huaral" },
  {
    value: "union-saint-gilloise-bruxelles-brussels",
    name: "Royal Union Saint-Gilloise",
  },
  {
    value: "union-santa-fe-ciudad-de-santa-fe-provincia-de-santa-fe",
    name: "CA Unión de Santa Fe",
  },
  {
    value: "unionistas-de-salamanca-salamanca",
    name: "CD Unionistas de Salamanca CF",
  },
  {
    value: "universidad-catolica-santiago-de-chile",
    name: "CD Universidad Católica",
  },
  {
    value: "universidad-chile-santiago-de-chile",
    name: "CFP Universidad de Chile",
  },
  { value: "universitario-lima", name: "Club Universitario de Deportes" },
  { value: "universitatea-cluj-cluj-napoca", name: "FC Universitatea Cluj" },
  {
    value: "universitatea-craiova-craiova",
    name: "Universitatea Craiova 1948 Club Sportiv",
  },
  { value: "ural-ekaterinburg", name: "FK Ural Sverdlovskaya Oblast" },
  { value: "ural-ii", name: "FK Ural-D Ekaterinburg" },
  { value: "urawa-reds-saitama", name: "Urawa Red Diamonds" },
  { value: "urozhay-krasnodar", name: "FK Urozhay" },
  { value: "ursl-vise-lixhe", name: "URSL Visé" },
  { value: "uta-arad-arad", name: "AFC UTA Arad" },
  { value: "utrecht-ii-utrecht", name: "FC Utrecht II" },
  { value: "utrecht-utrecht", name: "FC Utrecht" },
  { value: "v-varen-nagasaki-isahaya", name: "V-Varen Nagasaki" },
  { value: "valencia-ii-valencia", name: "Valencia CF Mestalla" },
  { value: "valencia-valencia", name: "Valencia CF" },
  { value: "valenciennes-valenciennes", name: "Valenciennes FC" },
  { value: "valerenga-oslo", name: "Vålerenga Fotball" },
  {
    value: "vancouver-whitecaps-vancouver-british-columbia",
    name: "Vancouver Whitecaps FC",
  },
  {
    value: "vancouver-whitecaps-vancouver-british-columbia-1986",
    name: "Vancouver",
  },
  { value: "varazdin-varazdin", name: "NK Varaždin" },
  { value: "vasas-budapest", name: "Budapesti Vasas SC" },
  {
    value: "vasco-da-gama-rio-de-janeiro-rio-de-janeiro",
    name: "CR Vasco da Gama",
  },
  { value: "vegalta-sendai-sendai", name: "Vegalta Sendai" },
  { value: "vejle-vejle", name: "Vejle Boldklub" },
  { value: "veles-moskva", name: "Veles Moskva" },
  {
    value: "velez-sarsfield-capital-federal-ciudad-de-buenos-aires",
    name: "Club Atlético Vélez Sarsfield",
  },
  { value: "venezia-mestre", name: "Venezia FC" },
  { value: "ventforet-kofu-kofu", name: "Ventforet Kofu" },
  { value: "verl-verl", name: "SC Verl 1924" },
  { value: "verspah-oita-oita-oita", name: "Verspah Oita" },
  { value: "vfb-oldenburg-oldenburg", name: "VfB Oldenburg 1897" },
  { value: "viborg-viborg", name: "Viborg FF" },
  { value: "vidi-szekesfehervar", name: "MOL Vidi FC" },
  {
    value: "viktoria-berlin-berlin",
    name: "FC Viktoria 1889 Berlin Lichterfelde-Tempelhof",
  },
  { value: "viktoria-koln-koln", name: "FC Viktoria Köln" },
  { value: "viktoria-plzen-plzen", name: "FC Viktoria Plzeň" },
  { value: "vilafranquense", name: "Vilafranquense" },
  {
    value: "villa-dalmine-campana-provincia-de-buenos-aires",
    name: "Club Villa Dálmine",
  },
  {
    value: "villa-san-carlos-berisso-provincia-de-buenos-aires",
    name: "CA Villa San Carlos",
  },
  { value: "villarreal-ii-villarreal", name: "Villarreal CF II" },
  { value: "villarreal-villarreal", name: "Villarreal CF" },
  { value: "vissel-kobe-kobe", name: "Vissel Kobe" },
  { value: "vitesse-arnhem", name: "SBV Vitesse" },
  { value: "vitoria-guimaraes-guimaraes", name: "Vitória Guimarães SC" },
  { value: "vitoria-guimaraes-ii-guimaraes", name: "Vitória SC Guimarães II" },
  { value: "vizela-caldas-de-vizela", name: "FC Vizela" },
  { value: "volendam-volendam", name: "FC Volendam" },
  { value: "volga-ulyanovsk-ulyanovsk", name: "FK Volga Ulyanovsk" },
  { value: "volgar-astrakhan-astrakhan", name: "FK Volgar Astrakhan" },
  { value: "volos-nfc-volos", name: "Volos New Football Club" },
  {
    value: "volta-redonda-volta-redonda-rio-de-janeiro",
    name: "Volta Redonda FC",
  },
  { value: "voluntari-voluntari", name: "FC Voluntari" },
  { value: "vorskla-poltava", name: "FC Vorskla Poltava" },
  { value: "vorwarts-steyr-steyr", name: "SK Vorwärts Steyr" },
  { value: "vvv-venlo", name: "VVV Venlo" },
  { value: "waasland-beveren-beveren-waas", name: "Waasland-Beveren" },
  { value: "waldhof-mannheim-mannheim", name: "SV Waldhof Mannheim 07" },
  { value: "warta-poznan-poznan", name: "KS Warta Poznań" },
  { value: "watford-watford", name: "Watford FC" },
  { value: "wattens-wattens", name: "WSG Tirol" },
  { value: "wellington-phoenix-wellington", name: "Wellington Phoenix FC" },
  { value: "werder-bremen-bremen", name: "SV Werder Bremen" },
  {
    value: "west-bromwich-albion-u21-west-bromwich",
    name: "West Bromwich Albion Under 21",
  },
  {
    value: "west-bromwich-albion-u23-west-bromwich",
    name: "West Bromwich Albion Under 23",
  },
  {
    value: "west-bromwich-albion-west-bromwich",
    name: "West Bromwich Albion FC",
  },
  { value: "west-ham-united-london", name: "West Ham United FC" },
  { value: "westerlo-westerlo", name: "KVC Westerlo" },
  {
    value: "western-sydney-wanderers-sydney",
    name: "Western Sydney Wanderers FC",
  },
  {
    value: "western-united-ccc89e00-a4e1-4e9e-97f0-256bcd003c07",
    name: "Western United FC",
  },
  { value: "widzew-lodz-lodz", name: "RTS Widzew Łódź" },
  { value: "wiener-sc-wien", name: "Wiener Sport-Club" },
  { value: "wigan-athletic-wigan", name: "Wigan Athletic FC" },
  { value: "willem-ii-tilburg", name: "Willem II" },
  { value: "winterthur-winterthur", name: "FC Winterthur" },
  { value: "wisla-krakow-krakow", name: "Wisła Kraków" },
  { value: "wisla-plock-plock", name: "Wisła Płock SA" },
  { value: "woking-woking-surrey", name: "Woking FC" },
  { value: "wolfsberger-ac-ii", name: "Wolfsberger Athletik Club Amateure" },
  { value: "wolfsberger-ac-wolfsberg", name: "RZ Pellets WAC" },
  { value: "wolfsburg-wolfsburg", name: "VfL Wolfsburg" },
  {
    value: "wolverhampton-wanderers-wolverhampton",
    name: "Wolverhampton Wanderers FC",
  },
  { value: "wuhan-three-towns", name: "Wuhan Three Towns FC" },
  {
    value: "wycombe-wanderers-high-wycombe-buckinghamshire",
    name: "Wycombe Wanderers FC",
  },
  { value: "wydad-casablanca-casablanca", name: "Wydad Athletic Club" },
  { value: "yangju-citizen-yangju", name: "Yangju Citizen FC" },
  { value: "yangpyeong-yangpyeong-county", name: "Yangpyeong FC" },
  { value: "yeni-corumspor-corum", name: "Yeni Çorumspor Spor Kulübü" },
  { value: "yeni-malatyaspor-malatya", name: "Yeni Malatya Spor Kulübü" },
  { value: "yenisey-ii-krasnoyarsk", name: "FK Yenisey Krasnoyarsk II" },
  { value: "yokohama-f-marinos-yokohama", name: "Yokohama F. Marinos" },
  { value: "yokohama-yokohama", name: "Yokohama FC" },
  { value: "young-boys-bern", name: "BSC Young Boys" },
  { value: "yscc-yokohama", name: "Yokohama Sports and Culture Club" },
  { value: "yverdon-sport-yverdon-les-bains", name: "Yverdon Sport FC" },
  {
    value: "zacatepec-siglo-xxi-zacatepec",
    name: "Club de Fútbol Zacatepec Siglo XXI",
  },
  { value: "zaglebie-lubin-lubin", name: "Zagłębie Lubin" },
  { value: "zamalek-al-qahirah-cairo", name: "Zamalek SC" },
  { value: "zamora-barinas", name: "Zamora Fútbol Club" },
  { value: "zbrojovka-brno-brno", name: "FC Zbrojovka Brno" },
  {
    value: "zenit-sankt-peterburg-st-petersburg",
    name: "FK Zenit St. Petersburg",
  },
  { value: "zhejiang-greentown-hangzhou", name: "Zhejiang Greentown FC" },
  { value: "zira-zir", name: "Zira IK" },
  { value: "znamya-noginsk-noginsk", name: "FK Znamya Noginsk" },
  { value: "zorya-luhans-k-lugansk", name: "FC Zorya Luhansk" },
  { value: "zulte-waregem-waregem", name: "SV Zulte-Waregem" },
  { value: "zurich-zurich", name: "FC Zürich" },
  { value: "zweigen-kanazawa-kanazawa", name: "Ishikawa FC Zweigen Kanazawa" },
];

export const isTeamEmpty = (teamId) => {
  return teamId === "" || teamId === "retired";
};
